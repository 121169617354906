import React from "react";
import {
	Button,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	Stack,
	Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as yup from "yup";

import InsidePage from "../../../Templates/InsidePage";
import { ApiBuilder, ApiHelper } from "../../../Utils/apiUtils";
import { AuthContext } from "../../../Context/AuthContext";

import { NotificationsContext } from "../../FunctionalComponents/snackbar";

export default function CreateConceptsAdmin() {
	const { AuthData } = AuthContext();

	const notification = React.useContext(NotificationsContext);

	const navigate = useNavigate();

	const [conceptGroups, setConceptGroups] = React.useState(null);

	const validationSchema = yup.object({
		name: yup.string("").required("El nombre es obligatorio"),
		description: yup.string("").required("La descripcion es obligatoria"),
		conceptGroups: yup
			.string()
			.required("El grupo de conceptos es obligatorio"),
		amount: yup
			.number()
			.required("El monto es obligatorio")
			.min(0, "solo se permiten numeros positivos")
			.typeError(
				({ originalValue }) =>
					`El monto debe ser un numero se recibió: ${originalValue}`
			),
		bankName: yup.string("").required("El nombre del banco es obligatorio"),
		accountName: yup
			.string("")
			.required("El nombre de cuenta es obligatorio"),
		accountNumber: yup
			.string("")
			.required("El numero de cuenta es obligatorio"),
		subAccountName: yup
			.string("")
			.required("El nombre de subcuenta es obligatorio"),
		subAccountNumber: yup
			.string("")
			.required("El numero de subcuenta es obligatorio"),
		externalKey: yup.string("").required("La llave externa es obligatoria"),
	});
	const formik = useFormik({
		initialValues: {
			name: "",
			description: "",
			conceptGroups: "",
			amount: "",
			bankName: "",
			accountName: "",
			accountNumber: "",
			subAccountName: "",
			subAccountNumber: "",
			externalKey: "",
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			createPaymentConcept(values);
		},
	});

	function createPaymentConcept(data) {
		console.log(data);
		const Query = JSON.stringify({
			query: `mutation {
                createPaymentConcept(
                    payment_concept_input:{
                        name: "${data.name}",
                        description: "${data.description}",
                        concept_group_uuid: "${data.conceptGroups}",
                        amount: ${data.amount},
                    },
                    payment_concept_data:{
                        bank_name: "${data.bankName}"
                        account_name: "${data.accountName}"
                        bank_account: "${data.accountNumber}"
                        bank_subaccount: "${data.subAccountNumber}"
                        subaccount_name: "${data.subAccountName}"
						external_key:"${data.externalKey}"
                    }
                ){
                    uuid
                    name
                    description
                    amount
                    concept_group{
                        uuid
                        name
                        description
                    }
                }
            }`,
		});
		console.log(Query);

		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${AuthData.token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				if (!res.data.errors) {
					notification.showMessage(
						"Concepto creado correctamente",
						"success"
					);
					navigate("/admin/Conceptos");
				} else {
					res.data.errors.map((error) => {
						throw new Error(error.message);
					});
				}
			})
			.catch((error) => {
				console.log(error);
				console.error(error);
				notification.showMessage(error.message, "error");
			});
	}
	function getConceptGroups() {
		const Query = JSON.stringify({
			query: `query {
				conceptGroups{
						uuid
						name
						description
					}

				
			}`,
		});
		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${AuthData.token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log(res);
				setConceptGroups(res.data.data.conceptGroups);
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
			});
	}
	React.useEffect(() => {
		getConceptGroups();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<InsidePage title="Generar Conceptos de pago">
			<Stack direction={"row"} spacing={4} sx={{ width: "100%" }}>
				<Stack spacing={1} width="100%">
					<Typography
						sx={{
							textTransform: "uppercase",
						}}
					>
						datos del concepto de pago:
					</Typography>

					<InputLabel>Nombre del concepto</InputLabel>
					<FormControl
						error={formik.errors.name && formik.touched.name}
						fullWidth
					>
						<OutlinedInput
							id="name"
							value={formik.values.name}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
						<FormHelperText>
							{formik.errors.name && formik.touched.name
								? formik.errors.name
								: " "}
						</FormHelperText>
					</FormControl>
					<InputLabel>Descripcion</InputLabel>
					<FormControl
						error={
							formik.errors.description &&
							formik.touched.description
						}
						fullWidth
					>
						<OutlinedInput
							id="description"
							value={formik.values.description}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							multiline
							minRows={2}
							maxRows={4}
						/>
						<FormHelperText>
							{formik.errors.description &&
							formik.touched.description
								? formik.errors.description
								: " "}
						</FormHelperText>
					</FormControl>
					{conceptGroups !== null && (
						<Stack width="100%">
							<InputLabel>Grupo de conceptos</InputLabel>
							<FormControl
								error={
									formik.errors.conceptGroups &&
									formik.touched
								}
								fullWidth
							>
								<Select
									id="conceptGroups"
									fullWidth
									displayEmpty
									placeholder="seleccione uno"
									defaultValue={conceptGroups[0].uuid}
									value={formik.values.conceptGroups}
									onChange={formik.handleChange(
										"conceptGroups"
									)}
									onBlur={formik.handleBlur("conceptGroups")}
									MenuProps={{
										MenuListProps: {
											disablePadding: true,
										}, 
									}}
								>
									{conceptGroups?.map((concept, index) => (
										<MenuItem
											key={index}
											value={concept.uuid}
										>
											{concept.name}
										</MenuItem>
									))}
								</Select>
								<FormHelperText>
									{formik.errors.conceptGroups &&
									formik.touched.conceptGroups
										? formik.errors.conceptGroups
										: " "}
								</FormHelperText>
							</FormControl>
						</Stack>
					)}
					<Button
						onClick={() => formik.handleSubmit()}
						variant="contained"
					>
						Crear concepto
					</Button>
				</Stack>

				<Stack width="100%">
					<Typography
						sx={{
							textTransform: "uppercase",
						}}
					>
						Datos bancarios:
					</Typography>
					<InputLabel>Nombre del banco</InputLabel>
					<FormControl
						error={
							formik.errors.bankName && formik.touched.bankName
						}
						fullWidth
					>
						<OutlinedInput
							id="bankName"
							value={formik.values.bankName}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
						<FormHelperText>
							{formik.errors.bankName && formik.touched.bankName
								? formik.errors.bankName
								: " "}
						</FormHelperText>
					</FormControl>
					<Stack direction={"row"} spacing={1}>
						<Stack width="100%">
							<InputLabel>Nombre de cuenta</InputLabel>
							<FormControl
								error={
									formik.errors.accountName &&
									formik.touched.accountName
								}
								fullWidth
							>
								<OutlinedInput
									id="accountName"
									value={formik.values.accountName}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
								<FormHelperText>
									{formik.errors.accountName &&
									formik.touched.accountName
										? formik.errors.accountName
										: " "}
								</FormHelperText>
							</FormControl>
						</Stack>
						<Stack width="100%">
							<InputLabel>Numero de cuenta</InputLabel>
							<FormControl
								error={
									formik.errors.accountNumber &&
									formik.touched.accountNumber
								}
								fullWidth
							>
								<OutlinedInput
									id="accountNumber"
									value={formik.values.accountNumber}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
								<FormHelperText>
									{formik.errors.accountNumber &&
									formik.touched.accountNumber
										? formik.errors.accountNumber
										: " "}
								</FormHelperText>
							</FormControl>
						</Stack>
					</Stack>

					<Stack direction={"row"} spacing={1}>
						<Stack width="100%">
							<InputLabel>Nombre de subcuenta</InputLabel>
							<FormControl
								error={
									formik.errors.subAccountName &&
									formik.touched.subAccountName
								}
								fullWidth
							>
								<OutlinedInput
									id="subAccountName"
									value={formik.values.subAccountName}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
								<FormHelperText>
									{formik.errors.subAccountName &&
									formik.touched.subAccountName
										? formik.errors.subAccountName
										: " "}
								</FormHelperText>
							</FormControl>
						</Stack>
						<Stack width="100%">
							<InputLabel>Numero de subcuenta</InputLabel>
							<FormControl
								error={
									formik.errors.subAccountNumber &&
									formik.touched.subAccountNumber
								}
								fullWidth
							>
								<OutlinedInput
									id="subAccountNumber"
									value={formik.values.subAccountNumber}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
								<FormHelperText>
									{formik.errors.subAccountNumber &&
									formik.touched.subAccountNumber
										? formik.errors.subAccountNumber
										: " "}
								</FormHelperText>
							</FormControl>
						</Stack>
					</Stack>
					<InputLabel>llave externa</InputLabel>
					<FormControl
						error={
							formik.errors.externalKey &&
							formik.touched.externalKey
						}
						fullWidth
					>
						<OutlinedInput
							id="externalKey"
							value={formik.values.externalKey}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
						<FormHelperText>
							{formik.errors.externalKey &&
							formik.touched.externalKey
								? formik.errors.externalKey
								: " "}
						</FormHelperText>
					</FormControl>
					<InputLabel>Monto</InputLabel>
					<FormControl
						error={formik.errors.amount && formik.touched.amount}
						fullWidth
					>
						<OutlinedInput
							id="amount"
							value={formik.values.amount}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
						<FormHelperText>
							{formik.errors.amount && formik.touched.amount
								? formik.errors.amount
								: " "}
						</FormHelperText>
					</FormControl>
				</Stack>
			</Stack>
		</InsidePage>
	);
}
