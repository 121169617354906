import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

import CustomCard from "../../../Templates/TemplateCard";

export default function EventsComp() {
	const events = [
		{
			Title: "Enfermedades y su evolución",
			Description:
				"lorem ipsum dolor sit amet. consectetur adipiscing elit. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			Price: "465.00 MXN",
		},
		{
			Title: "3ER Congreso de Medicina",
			Description:
				"lorem ipsum dolor sit amet. consectetur adipiscing elit. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			Price: "465.00 MXN",
		},
		{
			Title: "Medicina Retroactiva",
			Description:
				"lorem ipsum dolor sit amet. consectetur adipiscing elit. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			Price: "465.00 MXN",
		},
		{
			Title: "Razonamiento Medico",
			Description:
				"lorem ipsum dolor sit amet. consectetur adipiscing elit. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			Price: "465.00 MXN",
		},
	];

	return (
		<Paper variant="screenBase" sx={{ padding: 0 }}>
			<Stack sx={{ paddingBottom: 2 }}>
				<Typography gutterBottom variant="titleContent">
					NUEVOS EVENTOS DISPONIBLES
				</Typography>
				<Typography variant="content">
					Explora y observa los eventos disponibles, si te interesa alguno haz
					click en la flecha y continua con la inscripción&nbsp;
				</Typography>
			</Stack>
			<Box>
				<Grid container spacing={3}>
					{events.map((event) => (
						<Grid item md={6} key={event.Title}>
							<CustomCard
								title={event.Title}
								description={event.Description}
								price={event.Price}
							/>
						</Grid>
					))}
				</Grid>
			</Box>
		</Paper>
	);
}
