import {
	Avatar,
	Button,
	IconButton,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import addIcon from "../../../sources/Icons/AddIcon.svg";
import InsidePage from "../../../Templates/InsidePage";
import { BalancingContext } from "../../../Context/BalancingContext";
export default function BalancingByExp() {
	const { ContextUploadFile, BalancingData, conciliateByExp } =
		BalancingContext();
	function HandleUpload(file) {
		ContextUploadFile(file);
	}
	return (
		<InsidePage title="Conciliación por Exp">
			<Stack spacing={2} minWidth={"60%"} maxWidth={"80%"}>
				<Stack>
					<Typography>Archivo extensión .EXP</Typography>
					<Stack direction="row">
						<TextField
							sx={{
								"& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled":
									{
										WebkitTextFillColor: "#333",
										transition: "width 0.3s ease-in",
									},
							}}
							fullWidth
							placeholder="Suba un archivo"
							value={BalancingData.file?.name}
							disabled={true}
						/>
						<IconButton component="label">
							<Avatar variant="icon30x30" src={addIcon} />
							<input
								type="file"
								onChange={(e) => {
									const file = e.target.files[0];
									HandleUpload(file);
								}}
								hidden
								accept=".exp"
							/>
						</IconButton>
					</Stack>
				</Stack>
				<Button
					type="submit"
					variant="contained"
					fullWidth
					onClick={() => {
						conciliateByExp();
					}}
				>
					conciliar archivo
				</Button>
			</Stack>
		</InsidePage>
	);
}
