import { useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

export function BasicButton({
	type,
	variant,
	text,
	onClick,
	navigateTo,
	marginTop,
	width,
	visibility,
	disabled,
}) {
	const theme = useTheme();
	const isXl = useMediaQuery(theme.breakpoints.down("xl"));
	const isMd = useMediaQuery(theme.breakpoints.down("md"));
	if (window.location.pathname === "/initialPage") {
		return (
			<Button
				sx={{
					height: "35px",
					borderRadius: "8px",
					mt: marginTop ?? "",
					width: width ?? "50%",
					visibility: visibility ?? "visible",
				}}
				component={Link}
				type={type}
				variant={variant}
				onClick={onClick}
				disabled={disabled}
				to={navigateTo ?? null}
			>
				{text}
			</Button>
		);
	}
	if (window.location.pathname !== "/") {
		return (
			<Button
				sx={{
					height: "50px",
					borderRadius: "8px",
					mt: marginTop ?? "",
					width: width ?? "100%",
					visibility: visibility ?? "visible",
				}}
				component={Link}
				type={type}
				variant={variant}
				onClick={onClick}
				disabled={disabled}
				to={navigateTo ?? null}
			>
				{text}
			</Button>
		);
	}

	if (isMd) {
		return (
			<Button
				sx={{
					height: "50px",
					borderRadius: "8px",
					mt: marginTop ?? "",
					width: width ?? "80%",
					visibility: visibility ?? "visible",
					align: "center",
					alignSelf: "center",
				}}
				component={Link}
				type={type}
				variant={variant}
				onClick={onClick}
				disabled={disabled}
				to={navigateTo ?? null}
			>
				{text}
			</Button>
		);
	}
	return (
		<>
			{isXl ? ( // TODO:
				<Button
					sx={{
						height: "50px",
						borderRadius: "8px",
						mt: marginTop ?? "",
						width: width ?? "100%",
						visibility: visibility ?? "visible",
						align: "center",
						alignSelf: "center",
					}}
					component={Link}
					type={type}
					variant={variant}
					onClick={onClick}
					disabled={disabled}
					to={navigateTo ?? null}
				>
					{text}
				</Button>
			) : (
				// XL:
				<Button
					sx={{
						height: "60px",
						borderRadius: "8px",
						mt: marginTop ?? "",
						width: width ?? "525px",
						visibility: visibility ?? "visible",
						align: "center",
					}}
					component={Link}
					type={type}
					variant={variant}
					onClick={onClick}
					disabled={disabled}
					to={navigateTo ?? null}
				>
					{text}
				</Button>
			)}
		</>
	);
}
