import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";

import EditPencilIcon from "../../../sources/Icons/EditPencilIcon.svg";
import FileBoxIcon from "../../../sources/Icons/FileBoxIcon.svg";
import { Checkbox, IconButton } from "@mui/material";
import { DebtContext } from "../../../Context/DebtContext";
import { useNavigate } from "react-router-dom";
import React from "react";

export default function AdminConceptGroupCard({ concept, deleteMode }) {
	// const { selectConceptGroup } = DebtContext();

	const { AddStaged, RemoveStaged, staged } = DebtContext();
	const navigate = useNavigate();

	const isChecked =
		staged.find((s) => {
			s.uuid === concept.uuid;
		}) || false;

	const [checked, setChecked] = React.useState(isChecked);

	function handleChange() {
		if (checked) {
			setChecked(false);
			RemoveStaged(concept);
		} else {
			setChecked(true);
			AddStaged(concept);
		}
	}

	return (
		<Card
			sx={{
				display: "flex",
				flexDirection: "row",
				borderRadius: "15px",
				background: "#C82B00",
				color: "white",
				height: "180px",
				position: "relative",
			}}
			elevation={0}
		>
			{deleteMode && (
				<Checkbox
					color="secondary"
					sx={{ position: "absolute", right: 0 }}
					checked={checked}
				/>
			)}
			<CardActionArea
				onClick={() =>
					deleteMode
						? handleChange()
						: navigate("/admin/GruposConceptos/editar", {
							state: concept,
						  })
				}
			>
				<CardContent
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 4,
						height: "calc(100% - 32px)",
						justifyContent: "space-between",
					}}
				>
					<Typography
						sx={{ textTransform: "capitalize" }}
						variant="titleContent"
					>
						{concept.name}
					</Typography>
					<Stack direction="row" sx={{ alignItems: "center" }}>
						<Stack width="100%" spacing={1}>
							<Stack
								direction="row"
								sx={{ alignItems: "center" }}
								spacing={2}
							>
								<Stack direction="row" spacing={1}>
									<Chip
										label="Concepto"
										sx={{
											backgroundColor: "#FF4512",
											color: "white",
											borderRadius: "4px",
											cursor: "pointer",
										}}
									/>
									<IconButton
										sx={{
											backgroundColor: "#FF4512",
											color: "white",
											borderRadius: "4px",
											width: "32px",
											"&.Mui-disabled": {
												backgroundColor: "#FF4512",
											},
										}}
										disabled
									>
										<Avatar
											variant="icon15x15"
											src={EditPencilIcon}
										/>
									</IconButton>
								</Stack>
							</Stack>
						</Stack>
						<Avatar src={FileBoxIcon} variant="icon50x50" />
					</Stack>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}
