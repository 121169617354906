// import Concepts from "../Concepts2";
import Concepts from "../../../Components/student/Concepts/concepts";
import NoPaymentConcepts from "../../../Components/student/Concepts/noPaymentConcepts";
import { AuthContext } from "../../../Context/AuthContext";
import { DebtContext } from "../../../Context/DebtContext";
import { UserContext } from "../../../Context/StudentContext";
import { GetDebts } from "../../../Hooks/useDebtPlans";
import InsidePage from "../../../Templates/InsidePage";

export default function ConceptsInitial() {
	const { AuthData } = AuthContext();
	const { DebtData } = DebtContext();
	const { UserData } = UserContext();
	GetDebts(AuthData.token);

	switch (DebtData?.debtPlans?.length > 0) {
	case true:
		return (
			<InsidePage
				title={`Bienvenido, ${UserData.name}`}
				visibility={"hidden"}
			>
				<Concepts />
			</InsidePage>
		);
	case false:
		return (
			<InsidePage title="Adeudos pendientes">
				<NoPaymentConcepts />
			</InsidePage>
		);
	default:
		return <>default</>;
	}
}
