import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { BackButton } from "./input/BackButton";

export default function InsidePage({
	title,
	children,
	visibility,
	backAction,
}) {
	return (
		<Paper variant="screenBase">
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					width: "100%",
					justifyContent: "space-between",
					paddingBottom: 2,
				}}
			>
				<Typography variant="title" sx={{ textAlign: "start" }}>
					{title}
				</Typography>
				<BackButton
					func={backAction}
					visibility={visibility}
					text="volver"
				/>
			</Box>
			{children}
		</Paper>
	);
}
