import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Tooltip,
	IconButton,
	Avatar,
} from "@mui/material";
import deleteIcon from "../../../sources/Icons/deleteIcon.svg";
import { DebtContext } from "../../../Context/DebtContext";

export function ConceptsTable() {
	const { DebtData, DeleteConceptFromPlan } = DebtContext();

	return (
		<TableContainer component={Paper} elevation={0}>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>Concepto</TableCell>
						<TableCell>Cantidad</TableCell>
						<TableCell>Fecha de inicio</TableCell>
						<TableCell>Fecha de expiración</TableCell>
						<TableCell>Acciones</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{DebtData?.debtPlans?.plan_payments?.map(
						(concept, index) => (
							<TableRow
								key={index}
								sx={{
									"&:last-child td, &:last-child th": {
										border: 0,
									},
								}}
							>
								<TableCell>
									{concept.payment_concept.name}
								</TableCell>
								<TableCell>
									{concept.payment_concept.amount.toLocaleString(
										"es-MX",
										{
											style: "currency",
											currency: "MXN",
										}
									)}
									&nbsp;MXN
								</TableCell>
								<TableCell>{concept.start_date}</TableCell>
								<TableCell>{concept.expiry_date}</TableCell>
								<TableCell>
									<Tooltip title="Borrar conceptos" arrow>
										<IconButton
											onClick={() =>
												DeleteConceptFromPlan(
													concept.uuid
												)
											}
											variant="noPadding"
										>
											<Avatar
												variant="icon30x30"
												src={deleteIcon}
											/>
										</IconButton>
									</Tooltip>
								</TableCell>
							</TableRow>
						)
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
