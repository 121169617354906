//built in imports
import React from "react";
// third party imports
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
// internal imports

const NotificationsContext = React.createContext();

const initialState = {
	open: false,
	message: " ",
	severity: "info", //pq el mui llora si no le pongo un valor por defecto
};

const useNotifications = () => {
	const reducer = (state, action) => {
		switch (action.type) {
		case "SHOW_MESSAGE":
			return {
				open: action.open,
				message: action.message,
				severity: action.severity,
			};
		case "CLOSE_MESSAGE":
			return { ...state, open: false };
		case "RESET":
			return initialState;
		default:
			return state;
		}
	};

	const [state, dispatch] = React.useReducer(reducer, initialState);

	const showMessage = (message, level) => {
		dispatch({
			type: "SHOW_MESSAGE",
			open: true,
			message: message,
			severity: level,
		});
	};
	const closeMessage = () => {
		dispatch({ type: "CLOSE_MESSAGE" });
	};

	function reset() {
		dispatch({ type: "RESET" });
	}

	return { state, showMessage, closeMessage, reset };
};

const NotificationsSnackbar = ({ notifications }) => {
	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		notifications.closeMessage();
	};

	function handlereset() {
		notifications.reset();
	}

	return (
		<Snackbar
			open={notifications.state.open}
			onClose={handleClose}
			autoHideDuration={2500}
			TransitionProps={{ onExited: handlereset }}
			closeAfterTransition={true}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
		>
			<Alert
				onClose={handleClose}
				severity={notifications.state.severity}
			>
				{notifications.state.message}
			</Alert>
		</Snackbar>
	);
};

export const NotificationsProvider = ({ children }) => {
	const NotificationsObject = useNotifications();

	return (
		<NotificationsContext.Provider value={NotificationsObject}>
			{children}
			<NotificationsSnackbar notifications={NotificationsObject} />
		</NotificationsContext.Provider>
	);
};

export { NotificationsContext };
