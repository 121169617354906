/* eslint-disable react/no-unescaped-entities */
import {
	Button,
	DialogContent,
	DialogTitle,
	Stack,
	Typography,
} from "@mui/material";
import { AuthContext } from "../../../Context/AuthContext";
import { DebtContext } from "../../../Context/DebtContext";

export function ConfirmModal() {
	const { AuthData } = AuthContext();
	const { confirmSelectedDebtPlan, handleClose } = DebtContext();

	return (
		<>
			<DialogTitle>Confirmar Plan de Adeudos</DialogTitle>
			<DialogContent>
				<Typography>
					¿Estas seguro de elegir el plan de adeudos seleccionado?
				</Typography>
				<Typography>
					Recuerda que si eliges el plan incorrecto la unica manera de
					cambiarlo sera acudiendo a la administacion escolar
				</Typography>
				<Stack direction="row" spacing={1}>
					<Button
						variant="contained"
						text="confirmar"
						onClick={() => confirmSelectedDebtPlan(AuthData.token)}
					>
						confirmar
					</Button>
					<Button variant="outlined" onClick={handleClose}>
						volver
					</Button>
				</Stack>
			</DialogContent>
		</>
	);
}
