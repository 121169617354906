import { Fade } from "@mui/material";
import Avatar from "@mui/material/Avatar";

export function NekoArc() {
	const nekoark = (
		<Avatar
			sx={{
				placeSelf: "center",
				width: "50vw",
				height: "50vh",
				borderRadius: 0,
				"& .MuiAvatar-img": {
					objectFit: "contain",
				},
			}}
			src={
				"https://i.pinimg.com/originals/88/e8/46/88e846de93eb7d31eae1b5eb26e3c25b.gif"
			}
		/>
	);
	return (
		<Fade timeout={1000} in={true}>
			{nekoark}
		</Fade>
	);
}
