import { Button, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { AuthContext } from "../../../Context/AuthContext";
import { DebtContext } from "../../../Context/DebtContext";
import { ListDebtPlans } from "../../../Hooks/useDebtPlans";

export default function NoPaymentConcepts() {
	const { AuthData } = AuthContext();
	const { debtPlans } = ListDebtPlans(AuthData.token);
	const { setDebtPlan, handleOpen } = DebtContext();
	return (
		<Stack spacing={1}>
			<Typography variant="title" sx={{ textAlign: "start" }}>
				Selecciona tu Plan de Estudios
			</Typography>

			<Typography variant="content">
				Los pagos que aparecerán en esta plataforma serán de acuerdo a tu plan
				de estudios:
			</Typography>
			{debtPlans.length > 0 && (
				<TextField
					fullWidth
					select
					defaultValue={""}
					onChange={(e) => setDebtPlan(e.target.value)}
				>
					{debtPlans.map((debtPlan) => (
						<MenuItem key={debtPlan.uuid} value={debtPlan.uuid}>
							{debtPlan.name}
						</MenuItem>
					))}
				</TextField>
			)}
			<Typography variant="content">
				Ten cuidado al elegir tu plan de adeudos, en caso de elegir el plan
				incorrecto la única manera de cambiarlo será acudiendo a la
				administración escolar.
			</Typography>
			<Stack alignItems="flex-end">
				<Button
					variant="contained"
					sx={{ width: "20%" }}
					onClick={() => handleOpen("ConfirmDebtPlan")}
				>
					obtener plan
				</Button>
			</Stack>
		</Stack>
	);
}
