import Router from "./router";

import Box from "@mui/material/Box";

import "./App.css";

import Background from "../sources/Background.jpg";

import { UserContextProvider } from "../Context/StudentContext";
import { DebtContextProvider } from "../Context/DebtContext";

// import { useNavigate } from "react-router-dom";

function App() {
	// const Navigate = useNavigate();

	return (
		<UserContextProvider>
			<DebtContextProvider>
				<Box
					sx={{
						display: "grid",
						maxWidth: "100vw",
						overflowX: "hidden",
						minHeight: "100vh",
						backgroundImage: `url(${Background})`,
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat",
						backgroundPosition: "center",
					}}
				>
					<Router />
				</Box>
			</DebtContextProvider>
		</UserContextProvider>
	);
}

export default App;
