import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ApiBuilder, ApiHelper } from "../Utils/apiUtils";
import { NotificationsContext } from "../Components/FunctionalComponents/snackbar";

const Context = React.createContext();
export const AuthContextProvider = ({ children }) => {
	const navigate = useNavigate();
	const notification = React.useContext(NotificationsContext);
	const INITIAL_STATE = {
		token: null,
		role: null,
		authed: false,
		loading: true,
	};
	const AuthReducer = (state, action) => {
		switch (action.type) {
		case "LOGIN":
			localStorage.setItem("token", action.token);
			return {
				token: action.token,
				role: action.role,
				authed: true,
			};
		case "LOGOUT":
			navigate("/");
			localStorage.removeItem("token");
			return {
				token: null,
				role: null,
				authed: false,
			};
		case "LOCAL_STORAGE":
			return {
				token: action.token,
				role: action.role,
				authed: true,
			};
		case "SET_ROLE":
			return {
				...state,
				role: action.role,
				authed: true,
				token: action.token,
			};
		case "SET_LOADING_FALSE":
			return {
				...state,
				loading: false,
			};
		case "SET_LOADING_TRUE":
			return {
				...state,
				loading: true,
			};
		default:
			return null;
		}
	};
	const [state, dispatch] = React.useReducer(AuthReducer, INITIAL_STATE);

	const [open, setOpen] = React.useState(false);

	function login(values) {
		var formdata = new FormData();
		formdata.append("email", values.email);
		formdata.append("password", values.password);

		var request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/api/v1/login")
			.setData(formdata)
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				if (res.data.success && res.data.role === "STUDENT") {
					dispatch({
						type: "LOGIN",
						token: res.data.token,
						role: res.data.role,
					});
					navigate("/estudiante/inicio");
				} else {
					notification.showMessage(
						"Correo o contraseña incorrecto",
						"error"
					);
				}
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
				notification.showMessage(
					"Correo o contraseña incorrecto",
					"error"
				);
			});
	}

	function adminLogin(values) {
		var formdata = new FormData();
		formdata.append("email", values.email);
		formdata.append("password", values.password);

		var request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/api/v1/login")
			.setData(formdata)
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log(res);
				if (res.data.success && res.data.role === "ADMIN") {
					dispatch({
						type: "LOGIN",
						token: res.data.token,
						role: res.data.role,
					});
					navigate("/admin/HistorialGeneral");
				} else {
					notification.showMessage("Algo Salió mal", "error");
				}
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
				notification.showMessage("Algo Salió mal", "error");
			});
	}

	function checkToken() {
		const token = localStorage.getItem("token");
		const Query = JSON.stringify({
			query: `
        query{
            me{
				name
                role{
                    role
                }
            }
		}`,
		});
		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				const role = res.data.data.me.role.role;
				dispatch({ type: "SET_LOADING_FALSE" });
				dispatch({ type: "SET_ROLE", role, token });
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
				dispatch({ type: "SET_LOADING_FALSE" });
			});
	}
	function handleLogout() {
		dispatch({ type: "LOGOUT" });
		handleClose();
	}
	function handleClose() {
		setOpen(false);
	}
	function handleOpen() {
		setOpen(true);
	}

	function Modal() {
		return (
			<Dialog
				open={open}
				onClose={handleClose}
				hideBackdrop
				sx={{
					"& .MuiDialog-container": {
						paddingLeft: "15vw",
					},
					// "& .MuiPaper-root": {
					// 	width: "50vw",
					// 	maxWidth: "90vw",
					// 	height: "50vh",
					// 	paddingBottom: 4,
					// },
				}}
			>
				{/* {localComponent} */}
				<DialogTitle>¿Estas seguro de cerrar sesion?</DialogTitle>
				<DialogActions sx={{ justifyContent: "center" }}>
					<Button variant="contained" onClick={() => handleLogout()}>
						Cerrar sesion
					</Button>
					<Button variant="outlined" onClick={() => handleClose()}>
						Cancelar
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	return (
		<Context.Provider
			value={{
				AuthData: state,
				dispatch,
				login,
				adminLogin,
				checkToken,
				handleClose,
				handleOpen,
			}}
		>
			{children}
			<Modal />
		</Context.Provider>
	);
};
export function AuthContext() {
	return React.useContext(Context);
}
