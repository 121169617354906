import React from "react";
import {
	Button,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";

export default function DebtComponent({ debt }) {
	const steps = [
		{
			label: `${debt.created_at}`,
			description: `Imprime y paga con tu ficha de pago.`,
			component: (
				<Button
					variant="contained"
					onClick={() => handleNext()}
					href={`${process.env.REACT_APP_API_URL}/api/v1/files/payment_forms/${debt.uuid}`}
					target="_blank"
				>
					Imprimir ficha de pago
				</Button>
			),
		},
		{
			label: `${debt.created_at}`,
			description:
				"Espera que el pago sea comprobado por la institución.",
		},
	];
	const [activeStep, setActiveStep] = React.useState(0);

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	return (
		<>
			<Typography sx={{"fontWeight": "700", "fontSize": "1.2rem"}} >{`ASUNTO DEL ADEUDO: ${debt.payment_concept.name}`}</Typography>
			{/* <Typography>{`FECHA LIMITE DE PAGO: ${console.log(debt.payment_concept)}`}</Typography> */}
			<Stepper
				sx={{
					"& .MuiSvgIcon-root.MuiStepIcon-root.Mui-active": {
						color: "#8CBD00",
					},
					"& .MuiSvgIcon-root.MuiStepIcon-root.Mui-completed": {
						color: "#8CBD00",
					},
				}}
				activeStep={activeStep}
				orientation="vertical"
			>
				{steps.map((step, index) => (
					<Step expanded={true} key={index}>
						<StepLabel
							optional={
								index === 2 ? (
									<Typography variant="caption">
										Last step
									</Typography>
								) : null
							}
						>
							{step.label}
						</StepLabel>
						<StepContent>
							<Typography>{step.description}</Typography>
							<Box sx={{ mb: 2 }}>{step.component}</Box>
						</StepContent>
					</Step>
				))}
			</Stepper>
		</>
	);
}
