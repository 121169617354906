import React from "react";
import {
	Button,
	// Button,
	// Checkbox,
	FormControl,
	// FormControlLabel,
	// FormGroup,
	// InputLabel,
	MenuItem,
	OutlinedInput,
	Stack,
	TextField,
	Typography,
} from "@mui/material";

import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import InsidePage from "../../../Templates/InsidePage";
import { ApiBuilder, ApiHelper } from "../../../Utils/apiUtils";
import { AuthContext } from "../../../Context/AuthContext";
import { DebtContext } from "../../../Context/DebtContext";

export default function EditConceptGroup() {
	const { AuthData } = AuthContext();
	const { DebtData } = DebtContext();

	const navigate = useNavigate();

	// const [group, setGroup] = React.useState(
	// 	DebtData.concept.concept_group.name
	// );
	const location = useLocation();
	const data = location?.state;
	console.log(data);
	React.useEffect(() => {
		if (data === null) {
			navigate("/admin/GruposConceptos");
			return;
		}
	}, [data, navigate]);

	const validationSchema = yup.object({
		name: yup.string("").required("El nombre es obligatorio"),
		description: yup.string("").required("La descripcion es obligatoria"),
		concept: yup.array(""),
		// .required("seleccione un concepto"),
	});

	const formik = useFormik({
		initialValues: {
			name: data.name,
			description: data.description,
			concept: data.payment_concepts,
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			console.log(values);
			editConceptGroup(values);
			// adminLogin(values);
			// AddConceptToPlan(values);
		},
	});

	function editConceptGroup(values) {
		const Query = JSON.stringify({
			query: `mutation {
                edit_concept_group(
					uuid:"${DebtData.conceptGroup.uuid}"
                    name: "${values.name}",
                    description: "${values.description}"
                ){
                    uuid
                    name
                    description
                    created_at
                }
            }`,
		});
		console.log(Query);

		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${AuthData.token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log(res);
				navigate("/admin/GruposConceptos");
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
			});
	}

	return (
		<InsidePage title="Editar Grupo de Conceptos">
			<Stack spacing={2} width="100%">
				<Stack spacing={2} width="100%">
					<Stack direction={"row"} spacing={4} sx={{ width: "100%" }}>
						<Stack spacing={1} width="100%">
							<Typography
								sx={{
									textTransform: "uppercase",
								}}
							>
								datos del grupo de coceptos de pago:
							</Typography>
							<Typography>Nombre del grupo</Typography>
							<FormControl fullWidth>
								<OutlinedInput
									id="name"
									value={formik.values.name}
									onChange={formik.handleChange}
									error={
										formik.touched.name &&
										formik.errors.name
									}
									helperText={
										(formik.touched.name &&
											formik.errors.name) ||
										" "
									}
								/>
							</FormControl>
							<Typography>Descripcion</Typography>
							<FormControl fullWidth>
								<OutlinedInput
									value={formik.values.description}
									id="description"
									onChange={formik.handleChange}
									error={
										formik.touched.description &&
										formik.errors.description
									}
									helperText={
										(formik.touched.description &&
											formik.errors.description) ||
										" "
									}
								/>
							</FormControl>
							<Typography>Añadir conceptos</Typography>
							<TextField
								id="concept"
								value={formik.values.concept}
								onChange={formik.handleChange("concept")}
								error={
									formik.touched.concept &&
									formik.errors.concept
								}
								helperText={
									(formik.touched.concept &&
										formik.errors.concept) ||
									" "
								}
								select
								SelectProps={{
									MenuProps: {
										PaperProps: {
											style: {
												maxHeight: "250px",
											},
										},
									},
									multiple: true,
								}}
							>
								{DebtData.allConcepts.map((concept) => (
									<MenuItem
										id="concept"
										key={concept.uuid}
										value={concept.uuid}
									>
										{concept.name}
									</MenuItem>
								))}
							</TextField>
						</Stack>
					</Stack>
					<Button
						onClick={formik.handleSubmit}
						variant="contained"
						sx={{
							width: "250px",
						}}
					>
						Editar grupo
					</Button>
				</Stack>
			</Stack>
		</InsidePage>
	);
}
