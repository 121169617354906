import {
	Box,
	/* Button, */ Stack,
	/* TextField, */ Typography,
} from "@mui/material";
import React from "react";
import InsidePage from "../../../Templates/InsidePage";
import { RecordsTable } from "../../../Templates/RecordsDataTable";

// import { UserContext } from "../../Context/StudentContext";
import { DebtContext } from "../../../Context/DebtContext";

export default function GeneralRecords() {
	// const { dispatchUser } = UserContext();
	const { DebtData, GetAdminDebts } = DebtContext();

	React.useEffect(() => {
		GetAdminDebts();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<InsidePage title="Historial de Pagos/Adeudos">
			<Stack direction={"column"} sx={{ width: "100%" }}>
				{/* <Typography>pagos realizados:</Typography> */}
				<Stack
					direction={"row"}
					sx={{ justifyContent: "space-between" }}
				>
					<Typography>
						{DebtData?.debts?.filter((paid) => {
							return paid.is_paid === true;
						}).length > 1
							? `${
								DebtData?.debts?.filter((paid) => {
									return paid.is_paid === true;
								}).length
							  } pagos realizados`
							: `${
								DebtData?.debts?.filter((paid) => {
									return paid.is_paid === true;
								}).length
							  } pago realizado`}
					</Typography>
					{/* <Stack
						width="35%"
						direction={"row"}
						spacing={2}
						alignItems="center"
					>

						<Button
							sx={{ height: "100%" }}
							disabled
							variant="contained"
						>
							filtros
						</Button>
						<TextField fullWidth disabled={true} label="buscar" />
					</Stack> */}
				</Stack>
			</Stack>

			<Box
				sx={{
					marginTop: 4,
					width: "100%",
					height: "100%",
				}}
			>
				<RecordsTable />
			</Box>
		</InsidePage>
	);
}
