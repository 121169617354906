import { Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { DebtContext } from "../../../Context/DebtContext";
import ConceptsCard from "./card";
import NoDebt from "./NoAvailablePaymentConcepts";

export default function AvailablePayments() {
	const { DebtData, availablePayments } = DebtContext();
	// console.log(DebtData);
	const navigate = useNavigate();

	React.useEffect(() => {
		availablePayments();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{/* <Button onClick={() => handleOpen()}>Testing</Button> */}
			<Stack direction={"row"}>
				<Typography
					sx={{
						marginBottom: 2,
					}}
					variant="content"
					width="200%"
				>
					Selecciona el concepto que te corresponda, completa cada una
					de las operaciones que se indican y para obtén con éxito el
					NO ADEUDO.
				</Typography>
				{/* deprecated until eventes tab created */}
				{/* <Button variant="contained">Ver eventos</Button> */}
			</Stack>
			{DebtData.paymentConcepts.length > 0 ? (
				<Grid container spacing={2}>
					{DebtData.paymentConcepts.map((concept) => (
						<Grid key={concept.uuid} item xs={4}>
							{console.log("Students concept 🗿", concept)}
							<ConceptsCard concept={concept} />
						</Grid>
					))}
				</Grid>
			) : (
				<NoDebt />
			)}

			<Button
				sx={{
					marginTop: "auto",
				}}
				variant="contained"
				onClick={() => navigate("/PagosPendientes")}
			>
				Pagos pendientes
			</Button>
		</>
	);
}
