import { Stack, useTheme } from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { BackButton } from "../../Templates/input/BackButton";
import faeoG from "../../sources/faeoGuinda.png";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Def from "./index";
import { useMediaQuery } from "@mui/material";
import styles from "./Label.module.css";

function isBaseDirectory() {
	return window.location.pathname === "/";
}

export function LoginBase({ title, children }) {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<>
			{isMobile ? ( // Mobile
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",

						// minheight: "90vh",
						// maxheight: "90vh",
						alignItems: "center",
						alignSelf: "center",
					}}
				>
					<Box
						sx={{
							display: "grid",
							gridTemplateRows: "auto 1fr 1fr 1fr",
							flexDirection: "column",
							// width: "100%",
							minWidth: "calc(100% - 40px)",
							maxWidth: "calc(100% - 40px)",
							alignItems: "center",
							justifyItems: "center",
							padding: "20px",
							height: "100%",
							backgroundColor: "#ffffffcc",
							// opacity: "0.8",
						}}
					>
						<Box
							sx={{
								display: "flex",
								justifySelf: "flex-start",
								alignSelf: "flex-start",
								alignItems: "center",
								height: "fit-content",
							}}
						>
							<BackButton
								text="volver"
								visibility={
									isBaseDirectory() ? "hidden" : "visible"
								}
							/>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								width: "100%",
								alignItems: "center",
							}}
						>
							<Avatar
								sx={{ width: "150px", height: "150px" }}
								src={faeoG}
							/>
							<i className={styles.title}>
								Facultad de Enfermería y Obstetricia
							</i>
						</Box>
						<div className={styles.div}>
							<Typography
								sx={{
									display: "inline-flex",
									alignSelf: "flex-start",
									fontWeight: "bold",
									justifyContent: "center",
									alignItems: "center",
									height: "fit-content",
								}}
							>
								{title}
							</Typography>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									width: "100%",
								}}
							>
								{children}
							</Box>
						</div>
					</Box>
				</Box>
			) : (
				// TODO:
				<Box
					sx={{
						placeSelf: "center",
						display: "flex",
						flexDirection: "row",
					}}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: "40px",
						}}
					>
						<Def />
						<Paper variant="simpleBox">
							<Box
								sx={{
									display: "flex",
									justifySelf: "flex-start",
									alignSelf: "flex-start",
									alignItems: "center",
									height: "fit-content",
								}}
							>
								<BackButton
									text="volver"
									visibility={
										isBaseDirectory() ? "hidden" : "visible"
									}
								/>
							</Box>
							<Stack height="100%" marginBottom="">
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										width: "100%",
										alignItems: "center",
									}}
								>
									<Avatar
										sx={{ width: "150px", height: "150px" }}
										src={faeoG}
									/>
									<Typography
										color={"primary"}
										variant="titleFAEO"
									>
										Facultad de Enfermería y Obstetricia
									</Typography>
								</Box>
								{/* <span className={styles.space}></span> */}
								{title && (
									<Typography
										sx={{
											display: "inline-flex",
											alignSelf: "center",
											fontWeight: "bold",
										}}
									>
										{title}
									</Typography>
								)}
							</Stack>
							<Stack
								// display="flex"
								// minHeight="100vh"
								sx={{
									justifyContent: "center",
									alignItems: "center",
									width: "100%",
								}}
							>
								{children}
							</Stack>
						</Paper>
					</Box>
				</Box>
			)}
		</>
	);
}
