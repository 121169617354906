import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
	Button,
	TextField,
	Stack,
	Typography,
	MenuItem,
	List,
	ListItem,
} from "@mui/material";
import { ApiBuilder, ApiHelper } from "../../../Utils/apiUtils";
import { AuthContext } from "../../../Context/AuthContext";
import { DebtContext } from "../../../Context/DebtContext.jsx";
import InsidePage from "../../../Templates/InsidePage";

export default function CreateGroupConceptsAdmin() {
	const { AuthData } = AuthContext();

	const { getPaymentConcepts } = DebtContext();
	const [Concepts, setConcepts] = React.useState(null);
	const [conceptsTemp, setConceptsTemp] = React.useState([]);

	React.useEffect(() => {
		getPaymentConcepts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const validationConcepts = yup.object({
		concept: yup
			.string("")
			.required("El nombre del plan de estudio es requerido"),
	});
	const conceptsFormik = useFormik({
		initialValues: {
			concept: "",
		},
		validationSchema: validationConcepts,
		onSubmit: (values) => {
			console.log(values);
			addConcept(values);
			// adminLogin(values);
		},
	});

	const validationSchema = yup.object({
		name: yup.string("").required("El nombre es obligatorio"),
		description: yup.string("").required("La descripcion es obligatoria"),
		// .required("seleccione un concepto"),
	});
	const formik = useFormik({
		initialValues: {
			name: "",
			description: "",
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			console.log(values);
			createConceptGroup(values);
			// editConceptGroup(values);
			// adminLogin(values);
			// AddConceptToPlan(values);
		},
	});

	function getConceptGroups() {
		const Query = JSON.stringify({
			query: `query {
				paymentConcepts{
					uuid
					name
				}
			}`,
		});
		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${AuthData.token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log(res);
				setConcepts(res.data.data.paymentConcepts);
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
			});
	}
	React.useEffect(() => {
		getConceptGroups();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function createConceptGroup(values) {
		const Query = JSON.stringify({
			query: `mutation {
                createConceptGroup(
                    name: "${values.ame}",
                    description: "${values.description}"
                ){
                    uuid
                    name
                    description
                    created_at
                }
            }`,
		});
		console.log(Query);

		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${AuthData.token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log(res);
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
			});
	}

	function addConcept(concept) {
		setConceptsTemp([...conceptsTemp, concept]);
	}

	return (
		<InsidePage title="Generar Grupo de Conceptos de Pago">
			<Stack spacing={2} width="100%">
				<Stack direction={"row"} spacing={4} sx={{ width: "100%" }}>
					<Stack spacing={1} width="100%">
						<Typography
							sx={{
								textTransform: "uppercase",
							}}
						>
							datos del grupo de coceptos de pago:
						</Typography>
						<Stack fullWidth>
							<Typography>Nombre del concepto</Typography>
							<TextField
								id="name"
								value={formik.values.name}
								onChange={formik.handleChange}
								error={
									formik.touched.name && formik.errors.name
								}
								helperText={
									(formik.touched.name &&
										formik.errors.name) ||
									" "
								}
							/>
						</Stack>
						<Stack>
							<Typography>Descripción</Typography>
							<TextField
								id="description"
								value={formik.values.description}
								onChange={formik.handleChange}
								error={
									formik.touched.description &&
									formik.errors.description
								}
								helperText={
									(formik.touched.description &&
										formik.errors.description) ||
									" "
								}
								multiline
								minRows={4}
							/>
						</Stack>
						<Stack spacing={1}>
							<Typography>Agregar conceptos:</Typography>
							{Concepts?.length > 0 && (
								<TextField
									id="concept"
									value={conceptsFormik.values.concept}
									onChange={conceptsFormik.handleChange(
										"concept"
									)}
									defaultValue=""
									SelectProps={{
										MenuProps: {
											PaperProps: {
												style: {
													maxHeight: "170px",
												},
											},
										},
									}}
									error={
										conceptsFormik.touched.concept &&
										conceptsFormik.errors.concept
									}
									helperText={
										(conceptsFormik.touched.concept &&
											conceptsFormik.errors.concept) ||
										" "
									}
									select
								>
									{Concepts?.map((concept) => (
										<MenuItem
											id="concept"
											key={concept.uuid}
											value={concept.uuid}
										>
											{concept.name}
										</MenuItem>
									))}
								</TextField>
							)}
							<Button
								sx={{
									width: "50%",
								}}
								variant="contained"
								onClick={conceptsFormik.handleSubmit}
							>
								Add Concept
							</Button>
						</Stack>
					</Stack>
					<Stack width="100%">
						<Typography>Conceptos enlazados:</Typography>
						<List>
							{conceptsTemp.map((concept, index) => (
								<ListItem key={index}>
									{console.log(concept)}
									{concept.concept}
								</ListItem>
							))}
						</List>
					</Stack>
				</Stack>
			</Stack>
			<Button
				onClick={formik.handleSubmit}
				variant="contained"
				sx={{
					width: "250px",
					marginTop: "auto",
				}}
			>
				Crear concepto
			</Button>
		</InsidePage>
	);
}
