import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Tooltip,
	IconButton,
	Avatar,
	Stack,
	styled,
} from "@mui/material";
import { DebtContext } from "../Context/DebtContext";

import deleteIcon from "../sources/Icons/deleteIcon.svg";
import editIconFIlled from "../sources/Icons/editIconFilled.svg";

import downloadIcon from "../sources/Icons/downloadIcon.svg";

const tablePaper = styled(Paper)(() => ({
	backgroundColor: "#f5f5f5AA",
	backdropFilter: "blur(5px)",
}));

export function RecordsTable() {
	const { DebtData } = DebtContext();
	return (
		<TableContainer component={tablePaper}>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>Nombre</TableCell>
						<TableCell align="right">Correo</TableCell>
						<TableCell align="right">Asunto</TableCell>
						<TableCell align="right">Monto</TableCell>
						<TableCell align="right">Fecha de pago</TableCell>
						<TableCell align="right">Adeudo</TableCell>
						<TableCell align="right">Fecha de generación</TableCell>
						<TableCell align="center">Comprobante</TableCell>
						<TableCell align="center">Acciones</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{console.log("RECORDS DATA TABLE", DebtData)}
					{DebtData.debts?.map((debt) => (
						// TODO Handle multiple payments inside the same debt
						<TableRow
							key={debt.uuid}
							sx={{
								"&:last-child td, &:last-child th": {
									border: 0,
								},
							}}
						>
							{console.log(debt)}
							<TableCell component="th" scope="row">
								{debt?.user?.name}
							</TableCell>
							<TableCell align="right">
								{" "}
								{debt?.user?.email}{" "}
							</TableCell>
							<TableCell align="right">
								{debt?.payment_concept?.name}
							</TableCell>
							<TableCell align="right">
								$ {debt.payment_concept.amount}
							</TableCell>
							<TableCell align="right">
								{debt?.payments?.["0"]?.payment_date}
							</TableCell>
							<TableCell align="right">
								{debt?.is_paid ? "No adeudo" : "Adeudo"}
							</TableCell>
							<TableCell align="right">
								{debt?.created_at}
							</TableCell>
							<TableCell align="center">
								<Tooltip title="descargar recibo" arrow>
									<IconButton
										href={
											debt?.is_paid
												? `${process.env.REACT_APP_API_URL}/api/v1/files/invoice/${debt.uuid}`
												: null
										}
										target="_blank"
										disabled={!debt?.is_paid}
										sx={{
											"&.Mui-disabled": {
												filter: "brightness(0.5) grayscale(1) contrast(0.3)",
											},
										}}
									>
										<Avatar
											variant="icon30x30"
											src={downloadIcon}
										/>
									</IconButton>
								</Tooltip>
							</TableCell>
							<TableCell align="center">
								{/* {debt} */}
								<Stack
									direction="row"
									spacing={1}
									justifyContent={"center"}
								>
									<Tooltip title="Editar" arrow>
										<IconButton variant="16BR">
											<Avatar
												variant="icon30x30"
												src={editIconFIlled}
											/>
										</IconButton>
									</Tooltip>
									<Tooltip title="Eliminar" arrow>
										<IconButton variant="16BR">
											<Avatar
												variant="icon30x30"
												src={deleteIcon}
											/>
										</IconButton>
									</Tooltip>
								</Stack>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
