import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export default function BasicSnackbar({
	open,
	action,
	message,
	onClose,
	severity,
}) {
	return (
		<Snackbar
			open={open}
			autoHideDuration={6000}
			onClose={onClose}
			action={action}
		>
			<Alert onClose={onClose} severity={severity}>
				{message}
			</Alert>
		</Snackbar>
	);
}
