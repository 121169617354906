import InsidePage from "../../../Templates/InsidePage";

import BalancingStart from "../../../Components/Admin/balancing";

export default function Balancing() {
	return (
		<InsidePage title="Conciliación">
			<BalancingStart />
		</InsidePage>
	);
}
