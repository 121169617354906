import { createTheme } from "@mui/material";

export const theme = createTheme({
	typography: {
		fontFamily: "Lato, sans-serif",
		title: { fontSize: "48px", fontWeight: "700" },
		subtitle: { fontSize: "36px", fontWeight: "700" },
		titleContent: { fontSize: "20px", fontWeight: "700" },
		content: { fontSize: "20px", fontWeight: "600" },
		titleFAEO: { fontFamily: "open sans" },
	},
	palette: {
		primary: {
			main: "#CF112D",
		},
		secondary: {
			main: "#F5F5F5",
		},
	},
	components: {
		MuiPaper: {
			variants: [
				{
					props: { variant: "simpleBox" },
					style: {
						display: "grid",
						gridTemplateRows: "auto 1fr 1fr 1fr",
						alignItems: "center",
						justifyItems: "center",
						backgroundColor: "#F5F5F5",
						padding: 32,
						borderRadius: "20px",
						width: "30vw",
						height: "80vh",
					},
				},
				{
					props: { variant: "screenBase" },
					style: {
						display: "flex",
						flexDirection: "column",
						alignItems: "flex-start",
						position: "relative",
						justifyItems: "center",
						backgroundColor: "#F5F5F5",
						padding: 32,
						borderRadius: "0px",
						width: "calc(100% - 64px)",
						height: "100%",
					},
				},
			],
		},
		MuiButton: {
			defaultProps: {
				disableElevation: true,
			},
			variants: [
				{
					props: { variant: "backButton" },
					style: {
						color: "#000",
					},
				},
			],
		},
		MuiAvatar: {
			defaultProps: {
				imgProps: { draggable: false },
			},
			variants: [
				{
					props: { variant: "IconLarge" },
					style: {
						width: "432px",
						height: "432px",
						borderRadius: 0,
					},
				},
				{
					props: {
						variant: "icon15x15",
					},
					style: {
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
						width: "15px",
						height: "15px",
						borderRadius: 0,
					},
				},
				{
					props: {
						variant: "icon30x30",
					},
					style: {
						width: "30px",
						height: "30px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: {
						variant: "icon66x66",
					},
					style: {
						width: "66px",
						height: "66px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
				{
					props: { variant: "icon50x50" },
					style: {
						width: "50px",
						height: "50px",
						borderRadius: 0,
						"& .MuiAvatar-img": {
							objectFit: "contain",
						},
					},
				},
			],
		},
		MuiIconButton: {
			variants: [
				{
					props: { variant: "noPadding" },
					style: {
						"&.MuiButtonBase-root.MuiIconButton-root": {
							padding: 0,
						},
					},
				},
				{
					props: { variant: "16BR" },
					style: {
						borderRadius: "8px",
					},
				},
			],
		},
		MuiStack: {
			variants: [
				{
					props: { variant: "BottomSelector" },
					style: {
						alignItems: "center",
						marginTop: "auto",
						position: "sticky",
						bottom: 0,
						height: "50px",
						backgroundColor: "#f5f5f5aa",
						backdropFilter: "blur(2px)",
						width: "100%",
						paddingTop: "10px",
						paddingBottom: "10px",
					},
				},
			],
		},
		MuiTable: {
			defaultProps: {
				
			},
		},
	},
});
