import React from "react";

import {
	Avatar,
	Button,
	Grid,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { DebtContext } from "../../../Context/DebtContext";
import AddCard from "../../../Templates/cardAdd";
import InsidePage from "../../../Templates/InsidePage";
import AdminStudyPlansCards from "./Card";
import deleteIcon from "../../../sources/Icons/deleteIcon.svg";

export default function AdminStudyPlans() {
	const {
		getPaymentPlans,
		DebtData,
		handleOpen,
		staged,
		RestartStaged,
		DeleteStudyPlans,
	} = DebtContext();

	const [deleteMode, setDeleteMode] = React.useState(false);

	function BottomBarDefault() {
		return (
			<>
				<Tooltip title="Borrar conceptos" arrow>
					<IconButton
						onClick={() => setDeleteMode(true)}
						variant="noPadding"
					>
						<Avatar variant="icon30x30" src={deleteIcon} />
					</IconButton>
				</Tooltip>
			</>
		);
	}

	function BottomBarDelete() {
		return (
			<Stack
				direction="row"
				sx={{ width: "100%", justifyContent: "space-between" }}
			>
				<Stack
					spacing={1}
					direction="row"
					sx={{
						alignItems: "center",
					}}
				>
					<Tooltip title="Borrar conceptos" arrow>
						<IconButton
							onClick={() => setDeleteMode(true)}
							variant="noPadding"
						>
							<Avatar variant="icon30x30" src={deleteIcon} />
						</IconButton>
					</Tooltip>
					<Typography>Selecciona los conceptos a eliminar</Typography>
					<Typography fontWeight="bold">
						{staged.length}&nbsp;seleccionado(s)
					</Typography>
				</Stack>
				<Stack spacing={1} direction="row">
					<Button
						sx={{ minWidth: "250px", maxHeight: "30px" }}
						variant="contained"
						onClick={() => {
							DeleteStudyPlans();
							setDeleteMode(false);
							RestartStaged();
						}}
					>
						Eliminar seleccionados
					</Button>
					<Button
						sx={{ minWidth: "250px", maxHeight: "30px" }}
						variant="outlined"
						onClick={() => {
							RestartStaged();
							setDeleteMode(false);
						}}
					>
						Cancelar
					</Button>
				</Stack>
			</Stack>
		);
	}

	React.useEffect(() => {
		getPaymentPlans();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<InsidePage title="Planes de estudio" visibility="hidden">
			<Grid container spacing={2} sx={{ paddingBottom: 2 }}>
				{DebtData?.debtPlans?.length > 0 &&
					DebtData.debtPlans?.map((concept) => (
						<Grid key={concept.uuid} item xs={4}>
							{console.log(concept)}
							<AdminStudyPlansCards
								deleteMode={deleteMode}
								concept={concept}
							/>
						</Grid>
					))}
				<Grid item xs={4}>
					<AddCard
						onClick={() => handleOpen("OpenCreateStudyPlan")}
					/>
				</Grid>
			</Grid>
			<Stack spacing={1} direction="row" variant="BottomSelector">
				{deleteMode ? <BottomBarDelete /> : <BottomBarDefault />}
			</Stack>
		</InsidePage>
	);
}
