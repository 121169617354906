import React from "react";

import { BasicInput } from "../../../../Templates/input/textInput";
import { PasswordInput } from "../../../../Templates/input/textInput";
import { BasicButton } from "../../../../Templates/input/BasicButton";
import BasicSnackbar from "../../../../Templates/Snackbar";

import Grid from "@mui/material/Grid";
//! MUI ICONS
import { LoginBase } from "../../../../Components/Login/Loginbase";

export default function Registration() {
	const handleSubmit = () => {
		alert("I do nothing");
	};

	const [open, setOpen] = React.useState(false);

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpen(false);
	};

	return (
		<LoginBase title="REGISTRO PARA EVENTO">
			<BasicSnackbar
				open={open}
				onClose={handleClose}
				severity="error"
				message="Este boton aun no esta disponible"
			/>
			<Grid
				sx={{ alignSelf: "flex-start" }}
				container
				spacing={2}
				component={"form"}
				onSubmit={handleSubmit}
			>
				<Grid item xs={6}>
					<BasicInput
						id="name"
						label="NOMBRES"
						variant="standard"
						inputLabel="NOMBRES"
					/>
				</Grid>
				<Grid item xs={6}>
					<BasicInput
						id="LastName"
						label="APELLIDOS"
						variant="standard"
						inputLabel="APELLIDOS"
					/>
				</Grid>
				<Grid item xs={12}>
					<BasicInput
						id="email"
						label="CORREO ELECTRÓNICO"
						variant="standard"
						inputLabel="CORREO ELECTRÓNICO"
					/>
				</Grid>
				<Grid item xs={12}>
					<PasswordInput
						id="password"
						label="CONTRASEÑA"
						variant="standard"
						inputLabel="CONTRASEÑA"
					/>
				</Grid>
				<Grid item xs={12}>
					<BasicButton
						type="submit"
						variant="contained"
						text="continuar"
						onClick={handleClick}
					/>
				</Grid>
			</Grid>
		</LoginBase>
	);
}
