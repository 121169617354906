import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { DebtContext } from "../../../Context/DebtContext";
import DebtComponent from "./debtComponent";

export default function Debt() {
	const { DebtData, availablePayments } = DebtContext();

	const debts_pending = DebtData.debts.filter((debt) => {
		return debt.is_paid === false;
	});

	React.useEffect(() => {
		availablePayments();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{DebtData.debts && (
				<Paper variant="screenBase" sx={{ paddingLeft: 0 }}>
					<Stack sx={{ paddingBottom: 2 }}>
						<Typography gutterBottom variant="titleContent">
							{debts_pending.length > 1 || !debts_pending.length
								? `Tienes ${debts_pending.length} adeudos pendientes`
								: "Tienes un adeudo pendiente"}
						</Typography>
						<Typography variant="content">
							Completa cada una de las operaciones que a
							continuación indicamos para obtener con éxito.
							el&nbsp;
							<Typography variant="titleContent">
								NO ADEUDO
							</Typography>
						</Typography>
					</Stack>
					<Box>
						<Grid container spacing={2} sx={{ paddingLeft: 2 }}>
							{debts_pending.map((debt) => {
								console.log(debt);
								return (
									<Grid item key={debt.uuid}>
										<DebtComponent debt={debt} />
									</Grid>
								);
							})}
						</Grid>
					</Box>
				</Paper>
			)}
		</>
	);
}
