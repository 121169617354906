import { ApiBuilder } from "../../../../Utils/apiUtils";
import { ApiHelper } from "../../../../Utils/apiUtils";

import * as yup from "yup";
import { useFormik } from "formik";

import Grid from "@mui/material/Grid";
import { AuthContext } from "../../../../Context/AuthContext";

import { BasicInput } from "../../../../Templates/input/textInput";
import { PasswordInput } from "../../../../Templates/input/textInput";
import { BasicButton } from "../../../../Templates/input/BasicButton";

//! MUI ICONS
import { LoginBase } from "../../../../Components/Login/Loginbase";

export default function NoRegistration() {
	const { dispatch } = AuthContext();

	var myHeaders = new Headers();
	myHeaders.append("Accept", "application/json");

	const validationSchema = yup.object({
		email: yup
			.string("Introduce tu email")
			.email("Introduce un email valido")
			.required("Introduce tu email"),
		password: yup
			.string("Introduce tu contraseña")
			.min(3, "la contraseña debe tener al menos 3 caracteres")
			.required("Introduce tu contraseña"),
	});

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			var formdata = new FormData();
			formdata.append("email", values.email);
			formdata.append("password", values.password);

			var request = new ApiBuilder()
				.setMethod("POST")
				.setUrl("/api/v1/login")
				.setData(formdata)
				.build();
			new ApiHelper(request)
				.call()
				.then((res) => {
					console.log(res);
					res.data.success
						? dispatch({
							type: "LOGIN",
							token: res.data.token,
							role: res.data.role,
						})
						: alert("error");
				})
				.catch((error) => {
					console.log("error");
					console.error(error);
					alert(error);
				});
		},
	});

	return (
		<LoginBase title="PERSONA NO MATRICULADA">
			<Grid
				sx={{ alignSelf: "flex-start" }}
				container
				spacing={2}
				component={"form"}
				onSubmit={formik.handleSubmit}
			>
				<Grid item xs={12}>
					<BasicInput
						id="email"
						label="Correo electrónico"
						value={formik.values.email}
						onChange={formik.handleChange}
						error={formik.touched.email && Boolean(formik.errors.email)}
						helperText={formik.touched.email && formik.errors.email}
						inputLabel={"CORREO ELECTRÓNICO"}
						variant="standard"
					/>
				</Grid>
				<Grid item xs={12}>
					<PasswordInput
						id="password"
						label="Contraseña"
						value={formik.values.password}
						onChange={formik.handleChange}
						error={formik.touched.password && Boolean(formik.errors.password)}
						helperText={formik.touched.password && formik.errors.password}
						inputLabel={"CONTRASEÑA"}
						variant="standard"
					/>
				</Grid>
				<Grid item xs={12}>
					<BasicButton
						type="submit"
						variant="contained"
						text="Iniciar sesión"
						onClick={formik.handleSubmit}
					/>
				</Grid>
			</Grid>
		</LoginBase>
	);
}
