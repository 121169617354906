import { ListItemButton, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import { DebtContext } from "../../Context/DebtContext";
export default function CustomList({
	item,
	setSelectedIndex,
	index,
	selectedIndex,
}) {
	const { DebtData } = DebtContext();
	const { AuthData } = AuthContext();

	const handleListItemClick = (event, index) => {
		console.log("event", event);
		console.log("index", index);
		setSelectedIndex(index);
		navigate(item.Url);
	};
	const location = useLocation();

	location.pathname === item.Url ? setSelectedIndex(index) : null;

	const navigate = useNavigate();
	return (
		<ListItemButton
			disableGutters
			sx={{
				borderRadius: "0 15px 15px 0",
				marginRight: 4,
				marginY: 0,
				color: "#fafafa",
				"&.MuiListItemButton-root": {
					marginY: 0.5,
					":hover": {
						backgroundColor: "#fafafa",
						color: "#CF112D",
					},
				},
				"& .MuiTypography-root": {
					fontWeight: "bold",
					fontSize: "1.2rem",
				},
				"&.Mui-selected": {
					backgroundColor: "#fafafa",
					color: "#CF112D",
				},

				transition:
					"background-color 50ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
			}}
			disabled={
				item.disabled ||
				(DebtData.debtPlans === 0 && AuthData.role === "STUDENT")
			}
			onClick={(e) => handleListItemClick(e, index)}
			selected={selectedIndex === index}
		>
			<ListItemText
				sx={{
					paddingLeft: 2,
					margin: 0,
					paddingY: 1.5,
				}}
			>
				{item.Name}
			</ListItemText>
		</ListItemButton>
	);
}
