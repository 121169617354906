import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import { LoginBase } from "../../../Components/Login/Loginbase";

export default function Start() {
	return (
		<LoginBase>
			<Stack width="100%" spacing={3} sx={{ alignSelf: "flex-start" }}>
				{/* <BasicButton
					type=""
					variant="contained"
					text="Soy estudiante"
					navigateTo="/login/alumno"
				/>
				<BasicButton
					type=""
					variant="outlined"
					text="No soy estudiante"
					navigateTo="/login/noMatriculado"
				/>
				<BasicButton
					type=""
					variant="text"
					text="Quiero registrarme a un evento"
					navigateTo="/registroEvento"
				/> */}
				<Button
					LinkComponent={Link}
					to="/login/alumno"
					variant="contained"
					fullWidth
				>
					Soy Estudiante
				</Button>
				<Button
					LinkComponent={Link}
					to="/login/noMatriculado"
					variant="outlined"
				>
					No soy estudiante
				</Button>
				<Button
					LinkComponent={Link}
					to="/registroEvento"
					variant="text"
				>
					quiero registrarme a un evento
				</Button>
			</Stack>
		</LoginBase>
	);
}
