import React from "react";

import EventsComp from "../../../Components/student/Events/Events";
import NoEvents from "../../../Components/student/Events/noEvents";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Switch from "@mui/material/Switch";

export default function Events() {
	const [events, setEvents] = React.useState(false);
	const handleChange = (event) => {
		setEvents(event.target.checked);
	};
	return (
		<Paper variant="screenBase">
			<Box
				sx={{
					display: "flex",
					width: "100%",
					justifyContent: "space-between",
				}}
			>
				<Typography variant="title" sx={{ textAlign: "start" }}>
					Eventos
					<Switch checked={events} onChange={handleChange} defaultChecked />
				</Typography>
				<Button
					variant="backButton"
					disableRipple
					startIcon={<ArrowBackIcon />}
				>
					<Typography>Volver</Typography>
				</Button>
			</Box>
			{events ? <EventsComp /> : <NoEvents />}
		</Paper>
	);
}
