import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { BasicButton } from "../Templates/input/BasicButton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { Avatar, Dialog, IconButton } from "@mui/material";

import closeIcon from "../sources/Icons/CloseIcon.svg";

export function SimpleModal({
	open,
	close,
	dialogTitle,
	dialogContent,
	dialogActions,
	dialogAmount,
	currency,
	openingDate,
	expirationDate,
}) {
	const options = { year: "numeric", month: "long", day: "numeric" };
	const startingDate = new Date(openingDate).toLocaleDateString(
		"es-MX",
		options
	);
	const endingDate = new Date(expirationDate).toLocaleDateString(
		"es-MX",
		options
	);
	return (
		<Dialog
			hideBackdrop
			sx={{
				"& .MuiDialog-container": {
					paddingLeft: "15vw",
				},
				"& .MuiPaper-root": {
					width: "50vw",
					maxWidth: "90vw",
					height: "50vh",
					paddingBottom: 4,
				},
			}}
			open={open}
		>
			<Stack
				sx={{ justifyContent: "space-between" }}
				direction="row"
				width={"100%"}
			>
				<DialogTitle
					sx={{
						fontSize: "48px",
						fontWeight: "700",
						paddingBottom: 0,
					}}
				>
					{dialogTitle}
				</DialogTitle>
				<IconButton
					sx={{
						alignSelf: "flex-start",
					}}
					disableRipple
					disableFocusRipple
					disableTouchRipple
				>
					<Avatar onClick={close} variant="icon15x15" src={closeIcon} />
				</IconButton>
			</Stack>
			<DialogContent
				sx={{
					display: "grid",
					fontSize: "20px",
					fontWeight: "600",
					paddingTop: 0,
				}}
			>
				{`Disponible del ${startingDate} al ${endingDate}`}
				<br />
				<br />
				{dialogContent}

				<Box sx={{ alignSelf: "flex-end" }}>
					{dialogAmount && "Inversión: " + dialogAmount + ` ${currency ?? "$"}`}
				</Box>
			</DialogContent>

			<DialogActions
				sx={{
					width: "75%",
					margin: "0 auto",
				}}
			>
				<BasicButton
					text="Obtener adeudo"
					variant="contained"
					onClick={() => {
						dialogActions();
					}}
				/>
				<BasicButton text="cancelar" variant="outlined" onClick={close} />
			</DialogActions>
		</Dialog>
	);
}

export function BasicModal({ close, open, title, content }) {
	return (
		<Dialog
			sx={{
				"& .MuiDialog-container": { paddingLeft: "15vw" },
				"& 	.MuiDialog-paper": { width: "60%" },
			}}
			onClose={close}
			open={open}
			hideBackdrop
		>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>{content}</DialogContent>
		</Dialog>
	);
}
