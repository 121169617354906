import React from "react";
import { DebtContext } from "../../../Context/DebtContext";
import AvailablePayments from "./availablePayments";

export default function Concepts() {
	const { availablePayments } = DebtContext();

	React.useEffect(() => {
		availablePayments();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <AvailablePayments />;
}
