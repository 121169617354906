import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import { useTheme } from "@mui/material";

const HelloWorld = () => {
	const theme = useTheme();
	const transitionTest = (
		<Box
			sx={{
				placeSelf: "center",
			}}
		>
			<Avatar
				variant="square"
				sx={{
					height: "70vh",
					width: "70vh",
					[theme.breakpoints.down("md")]: {
						width: "95vw",
						height: "95vw",
					},
				}}
				src={"https://pbs.twimg.com/media/FBD79suWEAIq-DI.jpg"}
			/>
		</Box>
	);
	return (
		<Fade timeout={500} in={true}>
			{transitionTest}
		</Fade>
	);
};

export default HelloWorld;
