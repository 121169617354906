import React from "react";

import {
	// Avatar,
	Button,
	// Button,
	// Checkbox,
	FormControl,
	FormHelperText,
	// FormControlLabel,
	// FormGroup,
	// IconButton,
	InputLabel,
	OutlinedInput,
	Select,
	Stack,
	Typography,
} from "@mui/material";

import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

import InsidePage from "../../../Templates/InsidePage";
// import AddIcon from "../../../sources/Icons/AddIcon.svg";
import { ApiBuilder, ApiHelper } from "../../../Utils/apiUtils";
import { AuthContext } from "../../../Context/AuthContext";

import ListItem from "@mui/material/ListItem";

export default function EditConceptsAdmin() {
	const { AuthData } = AuthContext();
	const navigate = useNavigate();

	const [localGroup, setLocalGroup] = React.useState(null);

	function findConcept() {
		const finded = conceptGroups?.find((concept) => {
			return concept.uuid === data?.concept_group.uuid;
		});
		console.log(
			"🚀 ~ file: editConcept.js:39 ~ finded ~ conceptGroups:",
			conceptGroups
		);
		console.log("🚀 ~ file: editConcept.js:41 ~ finded ~ finded:", finded);
		console.log(
			"🚀 ~ file: editConcept.js:40 ~ finded ~ data?.concept_group.uuid:",
			data?.concept_group.uuid
		);
		finded ? setLocalGroup(finded) : setLocalGroup(null);
	}

	const location = useLocation();
	const data = location?.state;

	React.useEffect(() => {
		if (data === null) {
			navigate("/admin/conceptos");
			return;
		}
		getConceptGroups();
		findConcept();

		//eslint-disable-next-line
	}, [data, navigate]);

	const [conceptGroups, setConceptGroups] = React.useState(null);
	const validationSchema = yup.object({
		name: yup.string("").required("El nombre es obligatorio"),
		description: yup.string("").required("La descripcion es obligatoria"),
		conceptGroups: yup
			.string()
			.required("El grupo de conceptos es obligatorio"),
		amount: yup
			.number()
			.required("El monto es obligatorio")
			.min(0, "solo se permiten numeros positivos")
			.typeError(
				({ originalValue }) =>
					`El monto debe ser un numero se recibió: ${originalValue}`
			),
		bankName: yup.string("").required("El nombre del banco es obligatorio"),
		accountName: yup
			.string("")
			.required("El nombre de cuenta es obligatorio"),
		accountNumber: yup
			.string("")
			.required("El numero de cuenta es obligatorio"),
		subAccountName: yup
			.string("")
			.required("El nombre de subcuenta es obligatorio"),
		subAccountNumber: yup
			.string("")
			.required("El numero de subcuenta es obligatorio"),
		externalKey: yup.string("").required("La llave externa es obligatoria"),
	});
	const formik = useFormik({
		initialValues: {
			name: data?.name,
			description: data?.description,
			conceptGroups: localGroup || {},
			amount: data?.amount,
			bankName: data?.payment_concept_data?.bank_name,
			accountName: data?.payment_concept_data?.account_name,
			accountNumber: data?.payment_concept_data?.bank_account,
			subAccountName: data?.payment_concept_data?.subaccount_name,
			subAccountNumber: data?.payment_concept_data?.bank_subaccount,
			externalKey: data?.payment_concept_data?.external_key,
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			console.log("submit");
			editPaymentConcept(values);
		},
	});

	function editPaymentConcept(values) {
		const Query = JSON.stringify({
			query: `mutation {
                editPaymentConcept(
                    uuid:"${data.uuid}"
                    payment_concept_data:{
                        bank_name: "${values.bankName}"
                        account_name: "${values.accountName}"
                        bank_account: "${values.accountNumber}"
                        bank_subaccount: "${values.subAccountNumber}"
                        subaccount_name: "${values.subAccountName}"
						external_key:"${values.externalKey}"
                    }
                    payment_concept_input:{
                        name: "${values.name}",
                        description: "${values.description}",
                        concept_group_uuid: "${values.conceptGroups}",
                        amount: ${values.amount},
                    },
                ){
                    uuid
                }
            }`,
		});
		console.log(Query);

		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${AuthData.token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log(res);
				navigate("/admin/Conceptos");
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
			});
	}

	function getConceptGroups() {
		const Query = JSON.stringify({
			query: `query {
				conceptGroups{
						uuid
						name
						description
					}

			}`,
		});
		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${AuthData.token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log(res);
				setConceptGroups(res.data.data.conceptGroups);
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
			});
	}

	React.useEffect(() => {
		conceptGroups !== null && console.log(formik.values);
	}, [conceptGroups, formik.values]);

	return (
		<InsidePage title="Editar Conceptos de pago">
			<Stack direction={"row"} spacing={4} sx={{ width: "100%" }}>
				<Stack spacing={1} width="100%">
					<Typography
						sx={{
							textTransform: "uppercase",
						}}
					>
						datos del concepto de pago:
					</Typography>
					<InputLabel>Nombre del concepto</InputLabel>
					<FormControl fullWidth>
						<OutlinedInput
							id="name"
							value={formik.values.name}
							onChange={formik.handleChange}
							error={formik.errors.name && formik.touched}
						/>
						<FormHelperText
							error={formik.errors.name && formik.touched}
						>
							{formik.errors.name ?? " "}
						</FormHelperText>
					</FormControl>
					<InputLabel>Descripcion</InputLabel>
					<FormControl fullWidth>
						<OutlinedInput
							id="description"
							value={formik.values.description}
							onChange={formik.handleChange}
							error={formik.errors.description && formik.touched}
						/>
						<FormHelperText
							error={formik.errors.description && formik.touched}
						>
							{formik.errors.description ?? " "}
						</FormHelperText>
					</FormControl>
					<Stack
						width={"100%"}
						direction={"row"}
						sx={{ alignItems: "center" }}
					>
						<Stack sx={{ width: "100%" }}>
							<InputLabel>Grupo de conceptos</InputLabel>
							<FormControl
								fullWidth
								sx={{ flexDirection: "row" }}
							>
								{conceptGroups !== null && (
									<Select
										id="conceptGroups"
										fullWidth
										value={formik.conceptGroups}
										onChange={formik.handleChange(
											"conceptGroups"
										)}
										MenuProps={{
											MenuListProps: {
												disablePadding: true,
											},
										}}
									>
										{conceptGroups?.map((concept) => (
											<ListItem
												key={concept.uuid}
												value={concept.uuid}
											>
												{concept.name}
											</ListItem>
										))}
									</Select>
								)}
							</FormControl>
						</Stack>
					</Stack>
				</Stack>

				<Stack width="100%">
					<Typography
						sx={{
							textTransform: "uppercase",
						}}
					>
						Datos bancarios:
					</Typography>
					<InputLabel>Nombre del banco</InputLabel>
					<FormControl
						error={
							formik.errors.bankName && formik.touched.bankName
						}
						fullWidth
					>
						<OutlinedInput
							id="bankName"
							value={formik.values.bankName}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
						<FormHelperText>
							{formik.errors.bankName && formik.touched.bankName
								? formik.errors.bankName
								: " "}
						</FormHelperText>
					</FormControl>
					<Stack direction={"row"} spacing={1}>
						<Stack width="100%">
							<InputLabel>Nombre de cuenta</InputLabel>
							<FormControl
								error={
									formik.errors.accountName &&
									formik.touched.accountName
								}
								fullWidth
							>
								<OutlinedInput
									id="accountName"
									value={formik.values.accountName}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
								<FormHelperText>
									{formik.errors.accountName &&
									formik.touched.accountName
										? formik.errors.accountName
										: " "}
								</FormHelperText>
							</FormControl>
						</Stack>
						<Stack width="100%">
							<InputLabel>Numero de cuenta</InputLabel>
							<FormControl
								error={
									formik.errors.accountNumber &&
									formik.touched.accountNumber
								}
								fullWidth
							>
								<OutlinedInput
									id="accountNumber"
									value={formik.values.accountNumber}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
								<FormHelperText>
									{formik.errors.accountNumber &&
									formik.touched.accountNumber
										? formik.errors.accountNumber
										: " "}
								</FormHelperText>
							</FormControl>
						</Stack>
					</Stack>

					<Stack direction={"row"} spacing={1}>
						<Stack width="100%">
							<InputLabel>Nombre de subcuenta</InputLabel>
							<FormControl
								error={
									formik.errors.subAccountName &&
									formik.touched.subAccountName
								}
								fullWidth
							>
								<OutlinedInput
									id="subAccountName"
									value={formik.values.subAccountName}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
								<FormHelperText>
									{formik.errors.subAccountName &&
									formik.touched.subAccountName
										? formik.errors.subAccountName
										: " "}
								</FormHelperText>
							</FormControl>
						</Stack>
						<Stack width="100%">
							<InputLabel>Numero de subcuenta</InputLabel>
							<FormControl
								error={
									formik.errors.subAccountNumber &&
									formik.touched.subAccountNumber
								}
								fullWidth
							>
								<OutlinedInput
									id="subAccountNumber"
									value={formik.values.subAccountNumber}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
								<FormHelperText>
									{formik.errors.subAccountNumber &&
									formik.touched.subAccountNumber
										? formik.errors.subAccountNumber
										: " "}
								</FormHelperText>
							</FormControl>
						</Stack>
					</Stack>
					<InputLabel>llave externa</InputLabel>
					<FormControl
						error={
							formik.errors.externalKey &&
							formik.touched.externalKey
						}
						fullWidth
					>
						<OutlinedInput
							id="externalKey"
							value={formik.values.externalKey}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
						<FormHelperText>
							{formik.errors.externalKey &&
							formik.touched.externalKey
								? formik.errors.externalKey
								: " "}
						</FormHelperText>
					</FormControl>
					<InputLabel>Monto</InputLabel>
					<FormControl
						error={formik.errors.amount && formik.touched.amount}
						fullWidth
					>
						<OutlinedInput
							id="amount"
							value={formik.values.amount}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
						<FormHelperText>
							{formik.errors.amount && formik.touched.amount
								? formik.errors.amount
								: " "}
						</FormHelperText>
					</FormControl>
				</Stack>
			</Stack>
			<Button onClick={() => formik.handleSubmit()} variant="contained">
				Editar concepto
			</Button>
		</InsidePage>
	);
}
