import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
// import CardContent from "@mui/material/CardContent";
// import Stack from "@mui/material/Stack";
// import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

// import ArrowRight from "../../../sources/Icons/ArrowRight.svg";
import { DebtContext } from "../../../Context/DebtContext";
import { Avatar } from "@mui/material";

import BookIcon from "../../../sources/Icons/BookIcon.svg";

export default function ConceptsCard({ concept }) {
	const { selectConcept } = DebtContext();

	return (
		<Card
			sx={{
				display: "flex",
				flexDirection: "row",
				borderRadius: "15px",
				background: "#CF112D",
				color: "white",
			}}
			elevation={0}
		>
			<CardActionArea onClick={() => selectConcept(concept)}>
				{console.log("concept 🗿", concept)}
				{/* <Stack sx={{ width: "100%" }}> */}
				<CardContent
					sx={{ display: "flex", flexDirection: "column", gap: 4 }}
				>
					<Typography
						sx={{ textTransform: "capitalize" }}
						variant="titleContent"
					>
						{concept.payment_concept.name}
					</Typography>
					<Stack direction="row" sx={{ alignItems: "center" }}>
						<Stack width="100%" spacing={1}>
							<Typography variant="content">
								del&nbsp;
								{new Date(
									concept?.start_date
								).toLocaleDateString("es-MX", {
									day: "numeric",
								})}
								&nbsp;de&nbsp;
								{new Date(
									concept?.start_date
								).toLocaleDateString("es-MX", {
									month: "short",
								})}
								&nbsp;al&nbsp;
								{new Date(
									concept?.expiry_date
								).toLocaleDateString("es-MX", {
									day: "numeric",
								})}
								&nbsp;de&nbsp;
								{new Date(
									concept?.expiry_date
								).toLocaleDateString("es-MX", {
									month: "short",
								})}
							</Typography>
							<Stack
								direction="row"
								sx={{ alignItems: "center" }}
								spacing={2}
							>
								<Chip
									label="Inversión"
									sx={{
										backgroundColor: "#F52D4B",
										color: "white",
									}}
								/>
								<Typography variant="content">
									{concept?.payment_concept.amount?.toLocaleString(
										"es-MX",
										{
											style: "currency",
											currency: "MXN",
										}
									)}
									&nbsp;MXN
								</Typography>
							</Stack>
						</Stack>
						<Avatar src={BookIcon} variant="icon50x50" />
					</Stack>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}
