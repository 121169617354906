import React from "react";
import { ApiBuilder, ApiHelper } from "../Utils/apiUtils";
import { NotificationsContext } from "../Components/FunctionalComponents/snackbar";
import { AuthContext } from "./AuthContext";

const Context = React.createContext();
export function BalancingContextProvider({ children }) {
	const notifications = React.useContext(NotificationsContext);
	const INITIAL_STATE = {
		file: {},
		fileUuid: null,
	};

	const { AuthData } = AuthContext();
	const token = AuthData.token;

	const BalancingReducer = (state, action) => {
		switch (action.type) {
		case "SET_FILE":
			return {
				...state,
				file: action.file,
			};
		case "SET_FILE_UUID":
			return {
				...state,
				fileUuid: action.payload,
			};
		default:
			return null;
		}
	};
	const [state, dispatch] = React.useReducer(BalancingReducer, INITIAL_STATE);

	function ContextUploadFile(file) {
		console.log("ContextUpload token", token);
		console.log("ContextUpload file", file);

		const Query = `{
            "query": "mutation ($file: Upload!){ uploadConciliationFile(conciliationFile: $file){ uuid }}",
            "variables": {
                "file": null
            }
        }`;
		const formData = new FormData();
		formData.append("file", file);
		formData.append("operations", Query);
		formData.append("map", JSON.stringify({ file: ["variables.file"] }));

		formData.forEach((val) => {
			console.log(val);
		});
		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(formData)
			.setHeaders({
				Authorization: `Bearer ${token}`,
				"Content-Type": "multipart/form-data",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				notifications.showMessage(
					"Archivo subido exitosamente",
					"success"
				);
				dispatch({
					type: "SET_FILE_UUID",
					payload: res.data.data.uploadConciliationFile.uuid,
				});
				dispatch({ type: "SET_FILE", file: file });
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
				notifications.showMessage(
					"Hubo un error al subir el archivo",
					"error"
				);
			});
	}

	function conciliateByExp() {
		if (!state.fileUuid) {
			notifications.showMessage(
				"Falto subir archivo para conciliar",
				"error"
			);
			return;
		}
		const Query = JSON.stringify({
			query: `mutation {
				bank_conciliate(
					fileUuid: "${state.fileUuid}"
					verbose: true
				){
					uuid
					user{
						name
						email
						userdata{
							curp
						}
					}
					debt{
						payment_concept{
							name
							amount
						}
					}
					description
					
				}
			}`,
		});

		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log(res);
				notifications.showMessage("conciliacion exitosa", "success");
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
				notifications.showMessage(
					"Hubo un error al conciliar",
					"error"
				);
			});
	}

	function conciliateByExcel(startDate, endDate) {
		console.log(startDate, endDate);
		const Query = JSON.stringify({
			query: `		mutation {
				sia_conciliate(
					startDate: "${startDate}"
					endDate: "${endDate}"
					fileUuid: "${state.fileUuid}"
					verbose: true
				){
					uuid
					user{
						name
						email
						userdata{
							curp
						}
					}
					debt{
						payment_concept{
							name
							amount
						}
					}
					description
					
				}
			}`,
		});

		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log(res);
				notifications.showMessage("conciliacion exitosa", "success");
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
				notifications.showMessage(
					"Hubo un error al conciliar",
					"error"
				);
			});
	}

	return (
		<Context.Provider
			value={{
				BalancingData: state,
				ContextUploadFile,
				conciliateByExp,
				conciliateByExcel,
			}}
		>
			{children}
		</Context.Provider>
	);
}
export function BalancingContext() {
	return React.useContext(Context);
}
