import React from "react";
import { ApiBuilder, ApiHelper } from "../Utils/apiUtils";
import { DebtContext } from "../Context/DebtContext";
import { UserContext } from "../Context/StudentContext";
export function ListDebtPlans(token) {
	const [debtPlans, setDebtPlans] = React.useState([]);

	React.useEffect(() => {
		const Query = JSON.stringify({
			query: `query{
                debt_plans{
                    uuid
                    name
                    description
                    created_at
                }
            }`,
		});

		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log("🗿", res);
				setDebtPlans(res.data.data.debt_plans);
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
				// alert(error);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { debtPlans };
}

export function GetDebts(token) {
	const { setDebtPlans, setDebts } = DebtContext();
	const { setUser } = UserContext();
	const Query = JSON.stringify({
		query: `query{
		me{
			uuid
			name
			email
			created_at
			userdata{
				curp
				rfc
				registration_number
			}
			role {
				role
			}
			debts{
				uuid
				payment_concept{
					name
					amount
					description
				}
				payments{
					amount
					folio
					reference
					payment_date
				}
				is_paid
				reference
				created_at
			}
			payments{
				debt{
					uuid
					is_paid
				}
				reference
				amount
				created_at
			}
			subscribed_plans{
				debt_plan{
					uuid
					name
					description
				}
			}
		}
	}
`,
	});
	React.useEffect(() => {
		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log("res from DebtPlans", res);
				console.log(
					res.data.data.me.name,
					res.data.data.me.email,
					res.data.data.me.uuid,
					res.data.data.me.debts
				);
				const user1 = {
					name: res.data.data.me.name,
					email: res.data.data.me.email,
					uuid: res.data.data.me.uuid,
				};
				const debts = res.data.data.me.debts;
				const subPlans = res.data.data.me.subscribed_plans;
				setUser(user1);
				setDebts(debts);
				setDebtPlans(subPlans);
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
				alert(error);
			}); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}
