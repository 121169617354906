import React from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { ApiBuilder } from "../../../Utils/apiUtils";
import { ApiHelper } from "../../../Utils/apiUtils";
import { AuthContext } from "../../../Context/AuthContext";
import InsidePage from "../../../Templates/InsidePage";
import AddIcon from "../../../sources/Icons/AddIcon.svg";
import venn from "../../../sources/Icons/venn.svg";
import tables from "../../../sources/Icons/Tables.svg";
import camera from "../../../sources/Icons/camera.svg";

// import Button from "@mui/material/Button";

export default function Concilation() {
	const { AuthData } = AuthContext();
	const [file, setFile] = React.useState(null);
	//const [uuid, setUUID] = React.useState(null);
	const [startingDate, setStartingDate] = React.useState(null);
	const [expirationDate, setExpirationDate] = React.useState(null);

	function ConcliateUUID(fileUuid) {
		const Query = JSON.stringify({
			query: ` mutation {
                conciliate(
					startDate: "${startingDate}",
					endDate: "${expirationDate}",
					fileUuid: "${fileUuid}",
					verbose: true
				){
                    uuid
                    user{
                        name 
                        email
                        userdata{
                            curp
                        }
                    }
                    debt{
                        payment_concept{
                            name
							amount
                        }
                    }
                    description
                    
                }
            }`,
		});
		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${AuthData.token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log(res);
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
			});
	}

	const handleSend = () => {
		const Query = `{
			"query": "mutation ($file: Upload!){ uploadConciliationFile(conciliationFile: $file){ uuid }}",
			"variables": {
				"file": null
			}
		}`;

		// console.log(Query);

		const formData = new FormData();
		formData.append("file", file);
		formData.append("operations", Query);
		formData.append("map", JSON.stringify({ file: ["variables.file"] }));

		formData.forEach((val) => {
			console.log(val);
		});

		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(formData)
			.setHeaders({
				Authorization: `Bearer ${AuthData.token}`,
				"Content-Type": "multipart/form-data",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log(res.data.data.uploadConciliationFile.uuid);
				//setUUID(res.data.data.uploadConciliationFile.uuid);
				ConcliateUUID(res.data.data.uploadConciliationFile.uuid);
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
			});
	};

	return (
		<InsidePage title="Conciliación">
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<Stack
					direction="row"
					sx={{ width: "100%", gap: "10px", height: "100%" }}
				>
					<Stack direction="column" sx={{ width: "100%" }}>
						<Stack
							direction="row"
							sx={{
								width: "100%",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<Typography>Archivos</Typography>
							<IconButton component="label" sx={{ marginX: "-8px" }}>
								<input
									type="file"
									onChange={(e) => {
										const file = e.target.files[0];
										setFile(file);
									}}
									// value={file}
									hidden
								/>
								<Avatar variant="icon30x30" src={AddIcon} />
							</IconButton>
						</Stack>
						<Box
							sx={{
								width: "100%",
								height: "100%",
								borderRadius: "8px",
								backgroundColor: "rgba(35, 31, 32, 0.1)",
							}}
						/>
					</Stack>
					<Stack direction="column" sx={{ width: "100%" }}>
						<Stack
							direction="row"
							sx={{
								width: "100%",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							{/* <Button onClick={handleSend}>Conciliar</Button> */}
							<Grid item md={12}>
								<Stack direction="row" spacing={1}>
									<Stack
										sx={{
											transition: "width 0.6s ease-in-out",
										}}
										direction={"column"}
									>
										<InputLabel
											sx={{
												overflow: "hidden",
												transition: "width 0.6s ease-in-out",
											}}
										>
											Rango inicial:
										</InputLabel>
										<DatePicker
											inputFormat="DD/MM/YYYY"
											format="DD/MM/YYYY"
											value={startingDate}
											onChange={(newValue) => {
												setStartingDate(newValue);
											}}
											// sx={{ width: activeConceptOnCreation ? "0%" : "100%" }}
											sx={{ overflow: "hidden" }}
											renderInput={(params) => (
												<TextField
													fullWidth
													sx={{
														overflow: "hidden",
														transition: "width 0.6s ease-in-out",
													}}
													{...params}
												/>
											)}
										/>
									</Stack>
									<Stack sx={{ width: "100%" }} direction={"column"}>
										<InputLabel>Rango Final</InputLabel>
										<DatePicker
											inputFormat="DD/MM/YYYY"
											format="DD/MM/YYYY"
											value={expirationDate}
											onChange={(newValue) => {
												setExpirationDate(newValue);
											}}
											renderInput={(params) => (
												<TextField fullWidth {...params} />
											)}
										/>
									</Stack>
								</Stack>
							</Grid>
							<Stack alignItems="center" direction="row" spacing={"4px"}>
								<Stack direction="column" spacing={"4px"}>
									<IconButton sx={{ margin: "-8px" }}>
										<Avatar variant="icon30x30" src={tables} />
									</IconButton>
									<IconButton sx={{ margin: "-8px" }}>
										<Avatar variant="icon30x30" src={camera} />
									</IconButton>
								</Stack>
								{/* <IconButton
									onClick={handleSend}
									sx={{ marginX: "-8px", marginY: "-8px" }}
								>
									<Avatar variant="icon66x66" src={venn} />
								</IconButton> */}
								<IconButton
									onClick={handleSend}
									sx={{ marginX: "-8px", marginY: "-8px" }}
								>
									<Avatar variant="icon66x66" src={venn} />
								</IconButton>
							</Stack>
						</Stack>
						<Box
							sx={{
								width: "100%",
								height: "100%",
								borderRadius: "8px",
								backgroundColor: "rgba(35, 31, 32, 0.1)",
							}}
						/>
					</Stack>
				</Stack>
			</LocalizationProvider>
		</InsidePage>
	);
}
