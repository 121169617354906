import Box from "@mui/material/Box";

export default function NotFound() {
	return (
		<Box display="flex" justifyContent={"center"}>
			<h1 style={{ color: "white", alignSelf: "center" }}>
				404 Page not found
			</h1>
		</Box>
	);
}
