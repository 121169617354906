import React from "react";

import { AuthContext } from "../../Context/AuthContext";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import BasicIconButton from "../../Templates/input/IconButton";
import { UserContext } from "../../Context/StudentContext";
import LogOut from "../../sources/Icons/LogOut.svg";
import CustomList from "./listComponent";
import { List } from "@mui/material";

export default function Sidebar() {
	const { AuthData, handleOpen } = AuthContext();
	const items = [
		{ Name: "Inicio", Url: "/estudiante/inicio" },
		{ Name: "Pagos", Url: "/Pagos" },
	];
	const adminItems = [
		{ Name: "Inicio", Url: "/admin", disabled: true },
		{ Name: "Conceptos", Url: "/admin/Conceptos" },
		{ Name: "Conciliación", Url: "/admin/Test/Conciliacion" },
		{ Name: "Pagos", Url: "/admin/HistorialGeneral" },
		// { Name: "Conciliación deprecated", Url: "/Conciliacion" },
		// { Name: "Planes de estudios", Url: "/CrearConceptos" },
		{ Name: "Planes de estudio", Url: "/admin/PlanesEstudio" },
		// { Name: "Grupos de conceptos", Url: "/GruposConceptos" },
	];

	const [selectedIndex, setSelectedIndex] = React.useState(null);

	const handleLogout = () => {
		handleOpen();
	};
	const { UserData } = UserContext();

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				minWidth: "15vw",
				maxWidth: "15vw",
				// minHeight: "100vh",
				// maxHeight: "100vh",
				backgroundColor: "primary.main",
				position: "static",
				top: 0,
				left: 0,
				height: "100vh",
				// height: "100%",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<Stack
					sx={{
						alignItems: "center",
						width: "9vw",
						marginTop: "10px",
					}}
				>
					<Avatar
						// src={"https://pbs.twimg.com/media/FBD79suWEAIq-DI.jpg"}
						sx={{
							width: "8vw",
							height: "8vw",
							aspectRatio: "1 / 1",
							border: "5px solid #fafafa",
							borderRadius: "50%",
						}}
					/>
					<Typography
						gutterBottom
						textAlign={"center"}
						variant="h5"
						color={"secondary"}
					>
						{UserData.name}
					</Typography>
				</Stack>
				<List sx={{ width: "100%" }}>
					{AuthData.role === "ADMIN"
						? adminItems.map((item, index) => (
							<CustomList
								key={item.Name}
								item={item}
								selectedIndex={selectedIndex}
								setSelectedIndex={setSelectedIndex}
								index={index}
							/>
						  ))
						: items.map((item, index) => (
							<CustomList
								key={item.Name}
								item={item}
								selectedIndex={selectedIndex}
								setSelectedIndex={setSelectedIndex}
								index={index}
							/>
						  ))}
				</List>
			</Box>
			<Box sx={{ marginTop: "auto", marginLeft: 1, marginBottom: 2 }}>
				<BasicIconButton
					onClick={handleLogout}
					icon={LogOut}
					title="cerrar sesión"
				/>
			</Box>
		</Box>
	);
}
