import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";

export function BackButton({ text, func, visibility }) {
	return (
		<Button
			onClick={func}
			component={Link}
			variant="backButton"
			disableRipple
			startIcon={<ArrowBackIcon />}
			to={back_unless_root(func ? null : -1)}
			sx={{
				visibility: visibility ?? "visible",
			}}
		>
			<Typography>{text}</Typography>
		</Button>
	);
}

function back_unless_root(destination) {
	if (window.location.pathname === "/"){
		return
	}
	return destination
}