import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";

export default function BasicIconButton({ icon, onClick, title }) {
	return (
		<Tooltip title={title} arrow>
			<IconButton
				onClick={onClick}
				sx={{
					aspectRatio: "1/1",
					width: "60px",
					justifySelf: "flex-end",
				}}
			>
				<Avatar src={icon} />
			</IconButton>
		</Tooltip>
	);
}
