import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import AlertIcon from "../../../sources/Icons/Alerta.svg";

export default function NoEvents() {
	return (
		<Box sx={{ display: "grid", width: "100%", height: "100%" }}>
			<Stack
				direction={"row"}
				sx={{ placeSelf: "center", alignItems: "center" }}
			>
				<Stack direction={"column"} spacing={1}>
					<Typography variant="subtitle">No hay eventos disponibles</Typography>
					<Typography variant="content">
						En esta pantalla aparecerán automáticamente los eventos que estén
						disponibles
					</Typography>
				</Stack>
				<Avatar variant="IconLarge" src={AlertIcon} />
			</Stack>
		</Box>
	);
}
