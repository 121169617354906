import httpCommon from "./commonAxios";

class ApiHelper {
	constructor(builder) {
		this.http = httpCommon;
		this.url = builder.url;
		this.method = builder.method;
		this.params = builder.params;
		this.data = builder.data;
		//merge http common headers with builder headers
		this.headers = Object.assign(
			{},
			this.http.defaults.headers,
			builder.headers
		);
	}

	call() {
		//console.log(this.http.defaults.baseURL + this.url);
		//console.log(this.headers);
		switch (this.method) {
		case "POST":
			var call = this.http.post(this.url, this.data, {
				params: this.params,
				headers: this.headers,
			});
			return call;
		case "GET":
		default:
			return this.http.get(this.url, {
				params: this.params,
				headers: this.headers,
			});
		}
	}
}

class ApiBuilder {
	constructor() {
		this.url = "";
		this.method = "";
		this.params = {};
		this.data = {};
		this.headers = {};
	}

	setUrl(url) {
		this.url = url;
		return this;
	}

	setMethod(method) {
		this.method = method;
		return this;
	}

	setParams(params) {
		this.params = params;
		return this;
	}

	setData(data) {
		this.data = data;
		return this;
	}

	setHeaders(headers) {
		this.headers = headers;
		return this;
	}

	build() {
		return new ApiHelper(this);
	}
}

export { ApiBuilder, ApiHelper };
