import React from "react";

export const Context = React.createContext();
export const UserContextProvider = ({ children }) => {
	const INITIAL_STATE = {
		name: null,
		email: null,
		uuid: null,
	};
	const UserReducer = (state, action) => {
		switch (action.type) {
		case "SET_USER":
			return {
				name: action.name,
				email: action.email,
				uuid: action.uuid,
			};
		case "RESET_USER":
			return {
				name: null,
				email: null,
				uuid: null,
			};
		case "USER_ME":
			return {
				name: action.user.name,
				email: action.user.email,
				uuid: action.user.uuid,
			};

		default:
			return null;
		}
	};

	function setUser(user) {
		console.log(user);
		dispatchUser({ type: "USER_ME", user });
	}

	const [state, dispatchUser] = React.useReducer(UserReducer, INITIAL_STATE);
	return (
		<Context.Provider value={{ UserData: state, dispatchUser, setUser }}>
			{children}
		</Context.Provider>
	);
};
export function UserContext() {
	return React.useContext(Context);
}
