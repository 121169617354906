import PaymentsTable from "../../../Components/student/payments/paymentsTable";
import InsidePage from "../../../Templates/InsidePage";

export default function Payments() {
	return (
		<InsidePage title="Pagos">
			<PaymentsTable />
		</InsidePage>
	);
}
