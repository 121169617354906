import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";

import ArrowRight from "../sources/Icons/ArrowRight.svg";
import { Typography } from "@mui/material";
import { SimpleModal } from "./modal";

export default function CustomCard({
	title,
	description,
	amount,
	// date,
	concept,
	action,
	currency,
	prop,
	open,
	setOpen,
}) {
	// const [open, setOpen] = React.useState(false);

	function handleOpen() {
		setOpen(true);
	}
	function handleClose() {
		setOpen(false);
	}
	return (
		<Card
			sx={{
				display: "flex",
				flexDirection: "row",
				borderRadius: "15px",
				boxShadow: "0px 4px 10px rgba(35, 31, 32, 0.15)",
			}}
		>
			<SimpleModal
				open={open}
				close={handleClose}
				dialogTitle={prop.name}
				dialogContent={prop.description}
				dialogAmount={prop.amount}
				dialogActions={() => action(prop.uuid)}
				// openingDate={prop.opening_date}
				// expirationDate={prop.expiration_date}
				currency="MXN"
			/>
			<Stack sx={{ width: "100%" }}>
				<CardContent>
					<Typography
						sx={{ textTransform: "uppercase" }}
						variant="titleContent"
					>
						{title}
					</Typography>
				</CardContent>
				<CardContent>
					<Typography variant="content">{description}</Typography>
				</CardContent>
				<CardContent>
					<Typography variant="content">
						{concept} ${amount} {currency}
					</Typography>
				</CardContent>
			</Stack>
			<CardActionArea
				onClick={handleOpen}
				sx={{
					display: "flex",
					background: "#CF112D",
					width: "20%",
				}}
			>
				<Avatar src={ArrowRight} />
			</CardActionArea>
		</Card>
	);
}
