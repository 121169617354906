import React from "react";
import Dialog from "@mui/material/Dialog";
import { ApiBuilder, ApiHelper } from "../Utils/apiUtils";
import { ConfirmModal } from "../Components/student/Concepts/confirmModal";
import { ConceptsModal } from "../Components/student/Concepts/cardModal";
import { AuthContext } from "./AuthContext";
import { NotificationsContext } from "../Components/FunctionalComponents/snackbar";
import CreateStudyPlan from "../Components/Admin/studyPlans/modal";
import { useNavigate } from "react-router-dom";

export const Context = React.createContext();
export const DebtContextProvider = ({ children }) => {
	const navigate = useNavigate();
	const notifications = React.useContext(NotificationsContext);
	const { AuthData } = AuthContext();
	const INITIAL_STATE = {
		debts: [],
		debtPlans: null,
		debtPlan: "",
		debtPlanName: "",
		confirm: false,
		paymentConcepts: [],
		concept: {},
		conceptGroup: {},
		allConcepts: [],
		conceptGroups: [],
		debtPlanAdmin: {},
	};

	console.log(INITIAL_STATE);
	const token = AuthData.token;

	function reducer(state, action) {
		switch (action.type) {
		case "SET_DEBT":
			return {
				...state,
				debts: action.payload,
			};
		case "SET_DEBT_PLAN":
			return {
				...state,
				debtPlan: action.payload,
			};
		case "SET_DEBTS_PLAN":
			return {
				...state,
				debtPlans: action.payload,
			};
		case "SET_PAYMENT_CONCEPTS":
			return {
				...state,
				paymentConcepts: action.payload,
			};
		case "SET_CONCEPT":
			return {
				...state,
				concept: action.payload,
			};
		case "SET_ALL_PAYMENT_CONCEPTS":
			return {
				...state,
				allConcepts: action.payload,
			};
		case "SET_ALL_CONCEPT_GROUPS":
			return {
				...state,
				conceptGroups: action.payload,
			};
		case "CREATED_NEW_DEBT_PLAN":
			return {
				...state,
				debtPlan: action.payload.debtPlanUuid,
				debtPlanName: action.payload.debtPlanName,
			};
		case "SET_CONCEPT_GROUP":
			return {
				...state,
				conceptGroup: action.payload,
			};
		case "SET_DEBT_PLAN_ADMIN":
			return {
				...state,
				debtPlanAdmin: action.payload,
			};
		default:
			return null;
		}
	}
	const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);

	function setDebtPlan(debtPlan) {
		dispatch({ type: "SET_DEBT_PLAN", payload: debtPlan });
	}

	function setDebts(debts) {
		console.log("setDebts debts", debts);
		dispatch({ type: "SET_DEBT", payload: debts });
	}

	function setDebtPlans(debts) {
		dispatch({ type: "SET_DEBTS_PLAN", payload: debts });
	}
	function setDebtPlansbyId(debts) {
		dispatch({ type: "SET_DEBTS_PLAN", payload: debts });
	}

	function selectConcept(concept) {
		dispatch({ type: "SET_CONCEPT", payload: concept });
		handleOpen("OpenConcept");
	}

	const [open, setOpen] = React.useState(false);
	const [component, setComponent] = React.useState("");
	const [staged, setStaged] = React.useState([]);
	const [localComponent, setLocalComponent] = React.useState(null);

	React.useEffect(() => {
		switch (component) {
		case "ConfirmDebtPlan":
			setLocalComponent(<ConfirmModal />);
			break;
		case "OpenConcept":
			setLocalComponent(<ConceptsModal />);
			break;
		case "OpenCreateStudyPlan":
			setLocalComponent(<CreateStudyPlan />);
			break;
		default:
			setLocalComponent(null);
			break;
		}
	}, [component]);

	function handleClose() {
		setOpen(false);
		setComponent(null);
	}
	function handleOpen(cmp) {
		setOpen(true);
		setComponent(cmp);
	}

	function availablePayments() {
		const Query = JSON.stringify({
			query: `query{
				available_payment_concepts{
					uuid
					created_at
					debt_plan{
						created_at
						description
						name
						plan_payments{
							created_at
							expiry_date
							start_date
							updated_at
							uuid
						}
					}
					expiry_date
					payment_concept{
						amount
						description
						name
						payment_concept_data{
							account_name
							bank_account
							bank_name
							bank_subaccount
							external_key
							iva
							subaccount_name
							uuid
						}
						unique
						uuid
					}
					start_date
					updated_at
				}
			}`,
		});
		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log("subbedPLan", res);
				dispatch({
					type: "SET_PAYMENT_CONCEPTS",
					payload: res.data.data.available_payment_concepts,
				});
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
			});
	}

	function confirmSelectedDebtPlan(token) {
		const Query = JSON.stringify({
			query: `			mutation {
					subscribe_to_debt_plan(
						debt_plan_uuid: "${state.debtPlan}"
					){
						uuid
						debt_plan{
							name
							description
						}
					}
				}`,
		});
		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log("subbedPLan", res);
				getPaymentPlans();
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
			});

		setOpen(false);
	}

	function AddDebt(concept) {
		const Query = JSON.stringify({
			query: `		mutation {
				addDebt(
					userUuid: "${token}",
					paymentConceptUuid: "${concept.uuid}"){
					uuid
					reference
					created_at
					payment_concept{
						name
						description
						amount
					}
				}
			}`,
		});
		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log(res);
				// console.log("subbedPLan", res);
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
			});
	}

	function addDebtByUuid(user_uuid) {
		const Query = JSON.stringify({
			query: `mutation {
						addDebt(
							userUuid: "${user_uuid}",
							planConceptUuid: "${state.concept.uuid}"){
							uuid
							reference
							created_at
							payment_concept{
								name
								description
								amount
							}
						}
					}`,
		});

		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log("res", res);
				getDebts();
				handleClose();
				notifications.showMessage(
					"Concepto añadido exitosamente",
					"success"
				);
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
				notifications.showMessage(
					"Hubo un error al añadir el concepto",
					"error"
				);
			});
	}

	function GetAdminDebts() {
		console.log("GetAdminDebts", token);
		// TODO remove user inside the payments user and only use the one in debt
		const Query = JSON.stringify({
			query: `query {
			debts{
				uuid
				reference
				payments{
					user{
						name
						email
					}
					created_at
					payment_date
				}
				payment_concept{
					name
					description
					amount
				}
				is_paid
				created_at
				user{
					name
					email
				}
			}
		}
`,
		});
		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log(res);
				dispatch({
					type: "SET_DEBT",
					payload: res.data.data.debts,
				});
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
				alert(error);
			}); // eslint-disable-next-line react-hooks/exhaustive-deps
	}

	function getDebts() {
		const Query = JSON.stringify({
			query: `query{
			me{
				uuid
				name
				email
				created_at
				userdata{
					curp
					rfc
					registration_number
				}
				role {
					role
				}
				debts{
					uuid
					payment_concept{
						name
						amount
						description
					}
					payments{
						amount
						folio
						reference
						payment_date
					}
					is_paid
					reference
					created_at
				}
				payments{
					debt{
						uuid
						is_paid
					}
					reference
					amount
					created_at
				}
				subscribed_plans{
					debt_plan{
						uuid
						name
						description
					}
				}
			}
		}
	`,
		});
		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log("res from DebtPlans", res);
				console.log(
					res.data.data.me.name,
					res.data.data.me.email,
					res.data.data.me.uuid,
					res.data.data.me.debts
				);
				const debts = res.data.data.me.debts;
				const subPlans = res.data.data.me.subscribed_plans;
				setDebts(debts);
				setDebtPlans(subPlans);
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
				alert(error);
			}); // eslint-disable-next-line react-hooks/exhaustive-deps
	}

	function getPaymentPlans() {
		const Query = JSON.stringify({
			query: `query{
                debt_plans{
                    uuid
                    name
                    description
                    created_at
					plan_payments{
						uuid
						start_date
						expiry_date
						payment_concept{
							name
							amount
						}
					}
                }
            }`,
		});

		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log("🗿", res);
				setDebtPlans(res.data.data.debt_plans);
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
				// alert(error);
			});
	}

	function getPaymentConcepts() {
		const Query = JSON.stringify({
			query: `query{
				paymentConcepts{
					uuid
					name
					payment_concept_data{
						uuid
						bank_name
						account_name
						bank_account
						subaccount_name
						bank_subaccount
						external_key
						iva
					}
					description
					amount
					concept_group{
						uuid
						name
						description
					}
				}
			}`,
		});

		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log(
					"getPaymentConcepts",
					res.data.data.paymentConcepts
				);

				dispatch({
					type: "SET_ALL_PAYMENT_CONCEPTS",
					payload: res.data.data.paymentConcepts,
				});
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
				// alert(error);
			});
	}

	function getConceptGroups() {
		const Query = JSON.stringify({
			query: `query {
				conceptGroups{
						uuid
						name
						description
						payment_concepts{
							name
							description
						}
					}

				
			}`,
		});
		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${AuthData.token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log(res);
				dispatch({
					type: "SET_ALL_CONCEPT_GROUPS",
					payload: res.data.data.conceptGroups,
				});
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
			});
	}

	function CreateDebtPlan({ name, description }) {
		const Query = JSON.stringify({
			query: `mutation {
					createDebtPlan(
						name:"${name}"
						description:"${description}"
					){
						uuid
						name
					}
				}
		`,
		});
		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log("res from CreateDebtPlan", res);
				handleClose();
				const debtPlanUuid = res.data.data.createDebtPlan.uuid;
				const debtPlanName = res.data.data.createDebtPlan.name;
				navigate("/admin/PlanesEstudio/Crear");
				dispatch({
					type: "CREATED_NEW_DEBT_PLAN",
					payload: { debtPlanUuid, debtPlanName },
				});
			})
			.catch((error) => {
				console.error(error);
				console.log("error");
				alert(error);
			});
	}

	function AddConceptToPlan(values) {
		console.log(values);
		const Query = JSON.stringify({
			query: `mutation {
				addConceptToPlan(
					debt_plan_uuid:"${state.debtPlan}"
					payment_concept_uuid:"${values.concept}"
					start_date:"${new Date(values.dateStart).toLocaleDateString("en-ZA")}"
					expiry_date:"${new Date(values.dateEnd).toLocaleDateString("en-ZA")}"
				){
					uuid
				}
			}
		`,
		});
		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log("res from AddConceptToPlan", res);
				getPlanByUuid();
			})
			.catch((error) => {
				console.error(error);
				console.log("error");
				alert(error);
			});
	}

	function AddConceptToPlan2(values) {
		console.log(values);
		const Query = JSON.stringify({
			query: `mutation {
				addConceptToPlan(
					debt_plan_uuid:"${values.planUuid}"
					payment_concept_uuid:"${values.concept}"
					start_date:"${new Date(values.dateStart).toLocaleDateString("en-ZA")}"
					expiry_date:"${new Date(values.dateEnd).toLocaleDateString("en-ZA")}"
				){
					uuid
				}
			}
		`,
		});
		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log("res from AddConceptToPlan", res);
				getPlanByUuid2(values.planUuid);
			})
			.catch((error) => {
				console.error(error);
				console.log("error");
				alert(error);
			});
	}

	function DeleteConceptFromPlan(uuid) {
		console.log(uuid);
		const Query = JSON.stringify({
			query: `mutation {
				removeConceptFromPlan(
					plan_concept_uuid:"${uuid}"
					plan_uuid:"${state.debtPlan}"
				){
					uuid
				}
			}
		`,
		});
		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log("res from AddConceptToPlan", res);
				getPlanByUuid();
			})
			.catch((error) => {
				console.error(error);
				console.log("error");
				alert(error);
			});
	}

	function getPlanByUuid() {
		const Query = JSON.stringify({
			query: `query{
                debt_plans(
					uuid:"${state.debtPlan}"
				){
                    uuid
                    name
                    description
                    created_at
					plan_payments{
						uuid
						start_date
						expiry_date
						payment_concept{
							name
							amount
						}
					}
                }
            }`,
		});

		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log("🗿", res);
				setDebtPlansbyId(res.data.data.debt_plans[0]);
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
				// alert(error);
			});
	}

	function getPlanByUuid2(planUuid) {
		const Query = JSON.stringify({
			query: `query{
                debt_plans(
					uuid:"${planUuid}"
				){
                    uuid
                    name
                    description
                    created_at
					plan_payments{
						uuid
						start_date
						expiry_date
						payment_concept{
							name
							amount
						}
					}
                }
            }`,
		});

		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log("🗿", res);
				setDebtPlansbyId(res.data.data.debt_plans[0]);
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
				// alert(error);
			});
	}

	function selectConceptAdmin(concept) {
		dispatch({ type: "SET_CONCEPT", payload: concept });
		navigate("/admin/conceptos/editar");
	}

	function selectConceptGroup(concept) {
		dispatch({ type: "SET_CONCEPT_GROUP", payload: concept });
		navigate("/admin/GruposConceptos/editar");
		getPaymentConcepts();
	}

	function selectStudyPlan(studyPlan) {
		console.log("studyPlan", studyPlan);
		dispatch({ type: "SET_DEBT_PLAN_ADMIN", payload: studyPlan });
		navigate("/admin/PlanesEstudio/Editar");
		getPaymentConcepts();
	}

	function AddStaged(concept) {
		const conceptState = [...staged];
		conceptState.push(concept.uuid);
		console.log(conceptState);
		setStaged(conceptState);
	}

	function RemoveStaged(concept) {
		const conceptState = [...staged];
		console.log(conceptState);
		const conceptsSubstraction = conceptState?.filter(
			(c) => c !== concept.uuid
		);
		console.log(conceptsSubstraction);
		setStaged(conceptsSubstraction);
	}

	function RestartStaged() {
		setStaged([]);
	}

	function DeleteConcepts() {
		console.log(staged);

		const Query = JSON.stringify({
			query: `		mutation {
				deletePaymentConcept(
				 uuids:[${staged.map((s) => `"${s}"`)}]
					){
						uuid
					}
			}`,
		});

		console.log(Query);

		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log("🗿", res);
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
				// alert(error);
			});
	}

	function DeleteConceptGroup() {
		console.log(staged);

		const Query = JSON.stringify({
			query: `mutation {
						delete_concept_group(
							uuids:[${staged.map((s) => `"${s}"`)}]
								){
									uuid
								}
				}`,
		});

		console.log(Query);

		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log("🗿", res);
				setDebtPlansbyId(res.data.data.debt_plans[0]);
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
				// alert(error);
			});
	}

	function DeleteStudyPlans() {
		console.log(staged);

		const Query = JSON.stringify({
			query: `mutation {
						deleteDebtPlans(
							uuids:[${staged.map((s) => `"${s}"`)}]
							){
								uuid
							}
					}`,
		});

		console.log(Query);

		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log("🗿", res);
				setDebtPlansbyId(res.data.data.debt_plans[0]);
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
				// alert(error);
			});
	}
	function Modal() {
		return (
			<Dialog
				open={open}
				onClose={handleClose}
				hideBackdrop
				sx={{
					"& .MuiDialog-container": {
						paddingLeft: "15vw",
					},
					"& .MuiPaper-root": {
						width: "50vw",
						maxWidth: "90vw",
						height: "50vh",
						paddingBottom: 4,
					},
				}}
			>
				{localComponent}
			</Dialog>
		);
	}

	return (
		<Context.Provider
			value={{
				DebtData: state,
				setDebtPlan,
				handleOpen,
				handleClose,
				confirmSelectedDebtPlan,
				setDebts,
				getDebts,
				setDebtPlans,
				selectConcept,
				availablePayments,
				AddDebt,
				addDebtByUuid,
				GetAdminDebts,
				getPaymentConcepts,
				getConceptGroups,
				getPaymentPlans,
				CreateDebtPlan,
				AddConceptToPlan,
				DeleteConceptFromPlan,
				selectConceptAdmin,
				selectConceptGroup,
				selectStudyPlan,
				AddStaged,
				RemoveStaged,
				RestartStaged,
				staged,
				DeleteConcepts,
				DeleteConceptGroup,
				DeleteStudyPlans,
				AddConceptToPlan2,
			}}
		>
			{children}
			<Modal />
		</Context.Provider>
	);
};
export function DebtContext() {
	return React.useContext(Context);
}
