import { Avatar, Card, CardActionArea } from "@mui/material";
import { Link } from "react-router-dom";

import AddIconLarge from "../sources/Icons/AddIconLarge.svg";

export default function AddCard({ to, onClick }) {
	return (
		<Card
			sx={{
				borderRadius: "15px",
				backgroundColor: "transparent",
				height: "172px",
				border: "4px solid #CF112D4D",
			}}
			elevation={0}
		>
			<CardActionArea
				sx={{
					display: "grid",
					height: "100%",
				}}
				LinkComponent={Link}
				to={to}
				onClick={onClick}
			>
				<Avatar sx={{ placeSelf: "center" }} src={AddIconLarge} />
			</CardActionArea>
		</Card>
	);
}
