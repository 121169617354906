// import React from "react";

import * as yup from "yup";

import { Button, MenuItem, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InsidePage from "../../../Templates/InsidePage";
import { ConceptsTable } from "./table";
import { useFormik } from "formik";
import { ApiBuilder, ApiHelper } from "../../../Utils/apiUtils";
import React from "react";
import { AuthContext } from "../../../Context/AuthContext";
import { DebtContext } from "../../../Context/DebtContext";
import { NotificationsContext } from "../../FunctionalComponents/snackbar";

export default function CreateStudyPlan() {
	const { AuthData } = AuthContext();
	const { DebtData, AddConceptToPlan } = DebtContext();
	const [conceptGroups, setConceptGroups] = React.useState(null);

	const notifications = React.useContext(NotificationsContext);
	const validationSchemaAddConcept = yup.object({
		concept: yup.string("").required("seleccione un concepto"),
		dateStart: yup.string("").required("seleccione una fecha de inicio"),
		dateEnd: yup
			.string("")
			.required("seleccione una fecha de finalizacion"),
	});

	const addConceptValidation = useFormik({
		initialValues: {
			concept: "",
			dateStart: "",
			dateEnd: "",
		},
		validationSchema: validationSchemaAddConcept,
		onSubmit: (values) => {
			console.log(values);
			// adminLogin(values);
			AddConceptToPlan(values);
		},
	});

	const validationSchemaSaveStudyPlan = yup.object({
		studyPlanName: yup
			.string("")
			.required("El nombre del plan de estudio es requerido"),
	});

	const SaveStudyPlanValidation = useFormik({
		initialValues: {
			studyPlanName: DebtData.debtPlanName,
		},
		validationSchema: validationSchemaSaveStudyPlan,
		onSubmit: (values) => {
			console.log(values);
			// adminLogin(values);
			notifications.showMessage("Plan creado", "success");
		},
	});

	function getConceptGroups() {
		const Query = JSON.stringify({
			query: `query {
				paymentConcepts{
					uuid
					name
				}
			}`,
		});
		const request = new ApiBuilder()
			.setMethod("POST")
			.setUrl("/graphql/authenticated")
			.setData(Query)
			.setHeaders({
				Authorization: `Bearer ${AuthData.token}`,
				"Content-Type": "application/json",
			})
			.build();
		new ApiHelper(request)
			.call()
			.then((res) => {
				console.log(res);
				setConceptGroups(res.data.data.paymentConcepts);
			})
			.catch((error) => {
				console.log("error");
				console.error(error);
			});
	}
	React.useEffect(() => {
		getConceptGroups();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<InsidePage title="Crear Plan de Estudio">
			<Stack
				sx={{
					width: "100%",
					height: "100%",
					// justifyContent: "space-between",
				}}
			>
				<Stack
					spacing={1}
					direction="row"
					sx={{
						width: "60%",
					}}
				>
					<Stack spacing={1} sx={{ width: "100%" }}>
						<Stack>
							<Typography>Nombre del Plan de Estudio</Typography>
							<TextField
								value={
									SaveStudyPlanValidation.values.studyPlanName
								}
								id="studyPlanName"
								onChange={SaveStudyPlanValidation.handleChange}
								error={
									SaveStudyPlanValidation.touched
										.studyPlanName &&
									SaveStudyPlanValidation.errors.studyPlanName
								}
								helperText={
									(SaveStudyPlanValidation.touched
										.studyPlanName &&
										SaveStudyPlanValidation.errors
											.studyPlanName) ||
									" "
								}
							/>
						</Stack>
						<Stack>
							<Typography>Agregar Conceptos</Typography>
							{conceptGroups?.length > 0 && (
								<TextField
									id="concept"
									value={addConceptValidation.values.concept}
									onChange={addConceptValidation.handleChange(
										"concept"
									)}
									error={
										addConceptValidation.touched.concept &&
										addConceptValidation.errors.concept
									}
									helperText={
										(addConceptValidation.touched.concept &&
											addConceptValidation.errors
												.concept) ||
										" "
									}
									defaultValue=""
									select
									SelectProps={{
										MenuProps: {
											PaperProps: {
												style: {
													maxHeight: "250px",
												},
											},
										},
									}}
									PaperProps={{
										style: { maxHeight: "250px" },
									}}
								>
									{conceptGroups?.map((concept) => {
										return (
											<MenuItem
												id="concept"
												key={concept.uuid}
												value={concept.uuid}
												sx={{
													"&.MuiButtonBase-root.MuiMenuItem-root:nth-of-type(even)":
														{
															backgroundColor:
																"#ccc",
														},
												}}
											>
												{concept.name}
											</MenuItem>
										);
									})}
								</TextField>
							)}
						</Stack>
						<Stack>
							<Stack direction="row">
								<Typography>
									Fechas de disponibilidad&nbsp;
								</Typography>
								<Typography color="#C9C9C9">
									(Inicio - Final)
								</Typography>
							</Stack>
							<Stack direction="row" spacing={1}>
								<LocalizationProvider
									dateAdapter={AdapterDayjs}
								>
									<DatePicker
										id="dateStart"
										sx={{ overflow: "hidden" }}
										renderInput={(params) => (
											<TextField
												fullWidth
												{...params}
												error={
													addConceptValidation.touched
														.dateStart &&
													Boolean(
														addConceptValidation
															.errors.dateStart
													)
												}
												helperText={
													(addConceptValidation
														.touched.dateStart &&
														addConceptValidation
															.errors
															.dateStart) ||
													" "
												}
											/>
										)}
										value={
											addConceptValidation.values
												.dateStart
										}
										onChange={(value) => {
											addConceptValidation.setFieldValue(
												"dateStart",
												value,
												(value =
													addConceptValidation.values
														.dateStart)
											);
										}}
									/>

									<DatePicker
										id="dateStart"
										sx={{ overflow: "hidden" }}
										renderInput={(params) => (
											<TextField
												fullWidth
												{...params}
												error={
													addConceptValidation.touched
														.dateStart &&
													Boolean(
														addConceptValidation
															.errors.dateEnd
													)
												}
												helperText={
													(addConceptValidation
														.touched.dateStart &&
														addConceptValidation
															.errors
															.dateStart) ||
													""
												}
											/>
										)}
										value={
											addConceptValidation.values.dateEnd
										}
										onChange={(value) => {
											addConceptValidation.setFieldValue(
												"dateEnd",
												value,
												(value =
													addConceptValidation.values
														.dateEnd)
											);
										}}
									/>
								</LocalizationProvider>
							</Stack>
						</Stack>
						<Button
							variant="contained"
							onClick={addConceptValidation.handleSubmit}
						>
							Agregar concepto de pago al plan de estudios
						</Button>
					</Stack>
				</Stack>
				<Stack spacing={2}>
					<Typography>Conceptos enlazados</Typography>
					<ConceptsTable />
					<Stack
						direction="row"
						spacing={2}
						sx={{
							justifyContent: "flex-end",
						}}
					>
						<Button
							variant="contained"
							onClick={SaveStudyPlanValidation.handleSubmit}
						>
							Guardar
						</Button>
						<Button variant="outlined">cancelar</Button>
					</Stack>
				</Stack>
			</Stack>
		</InsidePage>
	);
}
