import { Routes, Route, Outlet, Navigate } from "react-router-dom";

import HelloWorld from "../Components/HelloWorld";
import StudentLogin from "../Pages/Student/Login/Student";
import NoRegistration from "../Pages/Student/Login/unverifiedStudent";
import Start from "../Pages/Student/Login";
import Registration from "../Pages/Student/Login/EventRegistration";
import Sidebar from "../Components/Sidebar";
// import MyHistory from "../Components/student/Records";
import Events from "../Pages/Student/Events";
import GenerateEvents from "../Pages/Admin/Events";
import NotFound from "../Components/NotFound";
import Concepts from "../Pages/Student/Concepts";
import Balancing from "../Pages/Admin/Balancing";

//! MUI IMPORTS
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import Login from "../Pages/Admin/Login";

import { useTheme } from "@mui/material/styles";
import GeneralRecords from "../Pages/Admin/History";
import Concilation from "../Components/Admin/balancing/old";
import { NekoArc } from "../Components/HelloWorld/nekoArc";
// import { WorkspaceSandbox } from "../Components/workspace/workspace";
import BalancingByExp from "../Components/Admin/balancing/BalancingExp";
import BalancingByExcel from "../Components/Admin/balancing/BalancingExcel";
import { BalancingContextProvider } from "../Context/BalancingContext";
import Payments from "../Pages/Student/payments";
import React from "react";
import { AuthContext } from "../Context/AuthContext";
import PendingDebts from "../Pages/Student/Concepts/PendingDebts";
import { Backdrop, CircularProgress } from "@mui/material";
import ConceptsAdmin from "../Pages/Admin/Concepts";
import AdminConceptGroupPage from "../Pages/Admin/ConceptGroup";
import CreateConceptsAdmin from "../Components/Admin/concepts/createConcept";
import CreateGroupConceptsAdmin from "../Components/Admin/concepts/createConceptGroup";
import CreateStudyPlan from "../Components/Admin/studyPlans/createStudyPlan";
import StudyPlanPage from "../Pages/Admin/CreateStudyPlan";
import EditConceptsAdmin from "../Components/Admin/concepts/editConcept";
import EditConceptGroup from "../Components/Admin/conceptGroup/editConceptGroup";
import EditStudyPlan2 from "../Components/Admin/studyPlans/editStudyPlan2";

export default function Router() {
	const theme = useTheme();
	return (
		<Box
			sx={{
				display: "grid",
				overflowX: "hidden",
				height: "100%",
				backdropFilter: "brightness(55%) blur(5px)",
				[theme.breakpoints.down("md")]: {
					backdropFilter: "brightness(45%) blur(5px)",
				},
			}}
		>
			<BalancingContextProvider>
				<Routes>
					<Route path="/" element={<Start />} />
					<Route path="/ralsei" element={<HelloWorld />} />
					<Route path="admin/login" element={<Login />} />
					<Route path="/login/alumno" element={<StudentLogin />} />
					<Route
						path="/login/noMatriculado"
						element={<NoRegistration />}
					/>
					<Route path="/inicio" element={<Start />} />
					<Route path="/registroEvento" element={<Registration />} />
					<Route path="/NekoArc" element={<NekoArc />} />
					<Route element={<SidebarLayout />}>
						<Route path="/Events" element={<Events />} />
						<Route path="/estudiante/inicio" element={<Concepts />} />
						<Route
							path="/PagosPendientes"
							element={<PendingDebts />}
						/>
						<Route path="/Pagos" element={<Payments />} />
					</Route>
					<Route element={<AdminLayout />}>
						<Route
							path="/admin/GruposConceptos"
							element={<AdminConceptGroupPage />}
						/>
						<Route
							path="/admin/GruposConceptos/crear"
							element={<CreateGroupConceptsAdmin />}
						/>
						<Route
							path="/admin/GruposConceptos/editar"
							element={<EditConceptGroup />}
						/>
						<Route path="/admin/Conceptos" element={<ConceptsAdmin />} />
						<Route
							path="/admin/conceptos/Crear"
							element={<CreateConceptsAdmin />}
						/>
						<Route
							path="/admin/Conceptos/Editar"
							element={<EditConceptsAdmin />}
						/>
						<Route
							path="/admin/GenerateEvent"
							element={<GenerateEvents />}
						/>
						<Route
							path="/admin/HistorialGeneral"
							element={<GeneralRecords />}
						/>
						<Route path="/Conciliacion" element={<Concilation />} />
						<Route
							path="/admin/Test/Conciliacion"
							element={<Balancing />}
						/>
						<Route
							path="/admin/Conciliacion/exp"
							element={<BalancingByExp />}
						/>
						<Route
							path="/admin/Conciliacion/excel"
							element={<BalancingByExcel />}
						/>
						<Route
							path="/admin/PlanesEstudio"
							element={<StudyPlanPage />}
						/>
						<Route
							path="/admin/PlanesEstudio/Crear"
							element={<CreateStudyPlan />}
						/>
						<Route
							path="/admin/PlanesEstudio/Editar"
							element={<EditStudyPlan2 />}
						/>
					</Route>
					<Route path="*" element={<NotFound />} />
				</Routes>
			</BalancingContextProvider>
		</Box>
	);
}

function AdminLayout() {
	const { AuthData, checkToken } = AuthContext();
	// const role = localStorage.getItem("role");
	const [unauthed, setUnauthed] = React.useState(Boolean);
	function Loading() {
		return (
			<Backdrop
				sx={{
					backgroundColor: "#333",
					color: "#93b1ff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={AuthData.loading}
				closeAfterTransition={true}
			>
				<CircularProgress
					color="inherit"
					sx={{
						position: "absolute",
					}}
					size="350px"
					thickness={1}
				/>
			</Backdrop>
		);
	}

	React.useEffect(() => {
		checkToken();

		setTimeout(() => {
			if (!AuthData.loading) {
				if (!AuthData.authed) {
					setUnauthed(true);
				} else {
					setUnauthed(false);
				}
			}
		}, [2000]);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [AuthData.loading]);
	if (unauthed && !AuthData.loading) {
		console.log("Admin unauthed");
		console.log("Admin unauthed", AuthData);
		return <Navigate to="admin/login" />;
	} else if (AuthData.role === "ADMIN" && !AuthData.loading) {
		console.log("Admin Authed", AuthData);
		return (
			<Stack
				direction={"row"}
				sx={{
					minHeight: "100vh",
					maxHeight: "100vh",
					overflow: "clip",
				}}
			>
				<Sidebar />
				<Stack
					sx={{
						overflowY: "auto",
						backgroundColor: "#fff",
						width: "100%",
						height: "100%",
					}}
				>
					<Outlet />
				</Stack>
				<Loading />
			</Stack>
		);
	} else if (!AuthData.loading) {
		console.log("Admin else", AuthData);
		return <Navigate to="admin/login" />;
	}
}

function SidebarLayout() {
	const { AuthData, checkToken } = AuthContext();
	// const role = localStorage.getItem("role");
	const [unauthed, setUnauthed] = React.useState(Boolean);
	function Loading() {
		return (
			<Backdrop
				sx={{
					backgroundColor: "#333",
					color: "#93b1ff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={AuthData.loading}
				closeAfterTransition={true}
			>
				<CircularProgress
					color="inherit"
					sx={{
						position: "absolute",
					}}
					size="350px"
					thickness={1}
				/>
			</Backdrop>
		);
	}

	React.useEffect(() => {
		checkToken();
		setTimeout(() => {
			if (!AuthData.loading) {
				if (!AuthData.authed) {
					setUnauthed(true);
				} else {
					setUnauthed(false);
				}
			}
		}, [2000]);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [AuthData.loading]);
	if (unauthed && !AuthData.loading) {
		console.log("student unAuthed");
		return <Navigate to="/" />;
	} else if (AuthData.role === "STUDENT" && !AuthData.loading) {
		console.log("Student");
		return (
			<Stack
				direction={"row"}
				sx={{
					minHeight: "100vh",
					maxHeight: "100vh",
					overflow: "clip",
				}}
			>
				<Sidebar />
				<Stack
					sx={{
						overflowY: "auto",
						backgroundColor: "#fff",
						width: "100%",
						height: "100%",
					}}
				>
					<Outlet />
				</Stack>
				<Loading />
			</Stack>
		);
	} else if (!AuthData.loading) {
		return <Navigate to="/" />;
	}
}
