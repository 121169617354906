import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import FormHelperText from "@mui/material/FormHelperText";

import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

export function BasicInput({
	value,
	onChange,
	error,
	label,
	id,
	variant,
	helperText,
	inputLabel,
	disabled,
	onblur,
}) {
	return (
		<FormControl fullWidth variant={variant}>
			<InputLabel>{inputLabel}</InputLabel>
			<Input
				id={id}
				value={value}
				onChange={onChange}
				error={error}
				fullWidth
				label={label}
				disabled={disabled}
				onBlur={onblur}
			/>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
}

export function PasswordInput({
	value,
	onChange,
	error,
	label,
	id,
	variant,
	helperText,
	inputLabel,
}) {
	const [showPassword, setShowPassword] = React.useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	return (
		<FormControl fullWidth variant={variant}>
			<InputLabel htmlFor="password">{inputLabel}</InputLabel>
			<Input
				id={id}
				label={label}
				type={showPassword ? "text" : "password"}
				value={value}
				onChange={onChange}
				error={error}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							onClick={handleClickShowPassword}
							onMouseDown={handleMouseDownPassword}
							edge="end"
						>
							{showPassword ? <VisibilityOff /> : <Visibility />}
						</IconButton>
					</InputAdornment>
				}
			/>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
}
