import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { BasicInput } from "../../../../Templates/input/textInput";
import { PasswordInput } from "../../../../Templates/input/textInput";
import { AuthContext } from "../../../../Context/AuthContext";
import * as yup from "yup";
import { useFormik } from "formik";
//! MUI ICONS
import { LoginBase } from "../../../../Components/Login/Loginbase";

import BasicSnackbar from "../../../../Templates/Snackbar";
import { Button, Stack } from "@mui/material";
export default function StudentLogin() {
	const { login } = AuthContext();

	var myHeaders = new Headers();
	myHeaders.append("Accept", "application/json");

	const validationSchema = yup.object({
		email: yup
			.string("Introduce tu email")
			.email("Introduce un email valido")
			.required("Introduce tu email"),
		password: yup
			.string("Introduce tu contraseña")
			.min(3, "la contraseña debe tener al menos 3 caracteres")
			.required("Introduce tu contraseña"),
	});

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			login(values);
		},
	});

	const [open, setOpen] = React.useState(false);

	const handleClick = () => {
		setOpen(true);
	};

	function handleClose() {
		console.log("handleClose");
		setOpen(false);
	}
	const handleCloseModal = () => {
		setOpenModal(!openModal);
	};
	const handleOpenModal = () => {
		setOpenModal(!openModal);
	};

	const [openModal, setOpenModal] = React.useState(false);

	return (
		<LoginBase title="ESTUDIANTE MATRICULADO">
			<BasicSnackbar
				open={open}
				onClose={handleClose}
				severity="error"
				message="Este boton aun no esta disponible"
			/>
			<Stack width="100%" spacing={2} marginTop={2}>
				<Button fullWidth variant="contained" onClick={handleClick}>
					INICIAR SESION CON GOOGLE
				</Button>
				<Button variant="text" onClick={handleOpenModal}>
					INICIAR SESION CON CORREO
				</Button>
			</Stack>

			<Dialog
				keepMounted={false}
				onClose={handleCloseModal}
				open={openModal}
				sx={{
					".MuiDialog-paper": {
						width: "500px",
						height: "400px",
					},
				}}
			>
				<DialogTitle textAlign={"center"}>INICIAR SESIÓN</DialogTitle>

				<Stack
					spacing={4}
					component={"form"}
					onSubmit={formik.handleSubmit}
					sx={{
						padding: "0px 30px 30px",
						height: "100%",
						justifyContent: "center",
					}}
				>
					<Stack spacing={2}>
						<BasicInput
							id="email"
							label="Correo electrónico"
							value={formik.values.email}
							onChange={formik.handleChange}
							error={
								formik.touched.email &&
								Boolean(formik.errors.email)
							}
							helperText={
								formik.touched.email && formik.errors.email
							}
							inputLabel={"CORREO ELECTRÓNICO"}
							variant="standard"
						/>
						<PasswordInput
							id="password"
							label="Contraseña"
							value={formik.values.password}
							onChange={formik.handleChange}
							error={
								formik.touched.password &&
								Boolean(formik.errors.password)
							}
							helperText={
								formik.touched.password &&
								formik.errors.password
							}
							inputLabel={"CONTRASEÑA"}
							variant="standard"
						/>
					</Stack>
					<Stack direction="row" spacing={4}>
						<Button
							type="submit"
							variant="contained"
							onClick={formik.handleSubmit}
							fullWidth
						>
							Iniciar sesión
						</Button>
						{/* <Button
							variant="outlined"
							onClick={handleClose}
							fullWidth
						>
							Cancelar
						</Button> */}
					</Stack>
				</Stack>
			</Dialog>
		</LoginBase>
	);
}
