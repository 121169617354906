import {
	Avatar,
	Button,
	IconButton,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import InsidePage from "../../../Templates/InsidePage";
import addIcon from "../../../sources/Icons/AddIcon.svg";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { BalancingContext } from "../../../Context/BalancingContext";

export default function BalancingByExcel() {
	const [startingDate, setStartingDate] = React.useState(null);
	const [endingDate, setEndingDate] = React.useState(null);

	const { ContextUploadFile, BalancingData, conciliateByExcel } =
		BalancingContext();
	function HandleUpload(file) {
		ContextUploadFile(file);
	}
	return (
		<InsidePage title="Conciliación por Exel">
			<Stack spacing={2} minWidth={"60%"} maxWidth={"80%"}>
				<Stack>
					<Typography>Archivo estensión .XLSX</Typography>

					<Stack direction="row">
						<TextField
							fullWidth
							sx={{
								"& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled": {
									WebkitTextFillColor: "#333",
								},
							}}
							placeholder="Suba un archivo"
							value={BalancingData.file?.name}
							disabled={true}
						>
							conciliar exp
						</TextField>
						<IconButton component="label">
							<Avatar variant="icon30x30" src={addIcon} />
							<input
								type="file"
								onChange={(e) => {
									const file = e.target.files[0];
									HandleUpload(file);
								}}
								hidden
							/>
						</IconButton>
					</Stack>
				</Stack>
				<Stack spacing={2}>
					<Typography>Rango de fechas</Typography>
					<Stack direction="row" spacing={1}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<Stack>
								<Typography>Fecha de inicio</Typography>
								<DatePicker
									inputFormat="DD/MM/YYYY"
									format="DD/MM/YYYY"
									value={startingDate}
									onChange={(newValue) => {
										setStartingDate(newValue);
									}}
									renderInput={(params) => <TextField fullWidth {...params} />}
								/>
							</Stack>
							<Stack>
								<Typography>Fecha de Fin</Typography>
								<DatePicker
									inputFormat="DD/MM/YYYY"
									format="DD/MM/YYYY"
									value={endingDate}
									onChange={(newValue) => {
										setEndingDate(newValue);
									}}
									renderInput={(params) => <TextField fullWidth {...params} />}
								/>
							</Stack>
						</LocalizationProvider>
					</Stack>
				</Stack>
				<Button
					sx={{ maxWidth: "250px" }}
					variant="contained"
					onClick={() => conciliateByExcel(startingDate, endingDate)}
				>
					conciliar archivo
				</Button>
			</Stack>
		</InsidePage>
	);
}
