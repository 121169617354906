import Debt from "../../../../Components/student/Concepts/debts";
import InsidePage from "../../../../Templates/InsidePage";

export default function PendingDebts() {
	return (
		<InsidePage title="Adeudos pendientes" visibility={"visible"}>
			<Debt />
		</InsidePage>
	);
}
