import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	IconButton,
	Avatar,
	Tooltip,
} from "@mui/material";

import downloadIcon from "../../../sources/Icons/downloadIcon.svg";
import { useEffect } from "react";
import { DebtContext } from "../../../Context/DebtContext";

export default function PaymentsTable() {
	const { DebtData, getDebts } = DebtContext();
	useEffect(() => {
		getDebts();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<TableContainer
			component={Paper}
			sx={{
				paddingTop: 4,
			}}
		>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead>
					<TableRow
						sx={{
							"& .MuiTableCell-root": {
								fontWeight: "bold",
								fontSize: "16px",
							},
						}}
					>
						<TableCell align="left">Asunto</TableCell>
						<TableCell align="left">Monto</TableCell>
						<TableCell align="left">Fecha de pago</TableCell>
						<TableCell align="left">Adeudo</TableCell>
						<TableCell align="left">Fecha de generación</TableCell>
						<TableCell align="center">Comprobante</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{DebtData.debts?.map((debt) => (
						<TableRow
							key={debt.uuid}
							sx={{
								"&:last-child td, &:last-child th": {
									border: 0,
								},
							}}
						>
							<TableCell align="left">
								{debt.payment_concept.name}
							</TableCell>
							<TableCell align="left">
								{(
									debt.payment_concept.amount
								).toLocaleString("es-MX", {
									style: "currency",
									currency: "MXN",
								})}
								&nbsp;MXN
							</TableCell>
							<TableCell align="left">
								{/* {debt} */}
								{/* TODO: FIXME: Añadir fecha de pago si pagado */}
								{debt?.is_paid ? debt.payments[0]?.payment_date : ""}
							</TableCell>
							<TableCell align="left">
								{debt?.is_paid ? "✅ No adeudo" : "❌ Adeudo"}
							</TableCell>
							<TableCell align="left">
								{debt?.created_at}
							</TableCell>
							<TableCell align="center">
								{/* {	debt?.is_paid && 
									<Button
										variant="contained"
										href={`${process.env.REACT_APP_API_URL}/api/v1/files/invoice/${debt.uuid}`}
										target="_blank"
									>
									Comprobante
									</Button>
								} */}
								<Tooltip title="descargar recibo" arrow>
									<IconButton
										href={
											debt?.is_paid
												? `${process.env.REACT_APP_API_URL}/api/v1/files/invoice/${debt.uuid}`
												: null
										}
										target="_blank"
										disabled={!debt?.is_paid}
										sx={{
											"&.Mui-disabled": {
												filter: "brightness(0.5) grayscale(1) contrast(0.3)",
											},
										}}
									>
										<Avatar
											variant="icon30x30"
											src={downloadIcon}
										/>
									</IconButton>
								</Tooltip>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
