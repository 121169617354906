import {
	DialogContent,
	DialogTitle,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { useFormik } from "formik";

import * as yup from "yup";
import Button from "@mui/material/Button";
import { DebtContext } from "../../../Context/DebtContext";

export default function CreateStudyPlan() {
	const { handleClose, CreateDebtPlan } = DebtContext();
	const validationSchema = yup.object({
		name: yup.string("").required("El nombre es necesario"),
		description: yup.string("").required("La descripcion es necesaria"),
	});

	const formik = useFormik({
		initialValues: {
			name: "",
			description: "",
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			console.log("🗿 values formik", values);
			// adminLogin(values);
			CreateDebtPlan({
				name: values.name,
				description: values.description,
			});
		},
	});

	return (
		<>
			<DialogTitle>
				<Typography variant="title">Crear plan de estudios</Typography>
			</DialogTitle>
			<DialogContent>
				<Stack spacing={2}>
					<Stack>
						<Stack>
							<Typography>Nombre:</Typography>
							<TextField
								value={formik.values.name}
								id="name"
								onChange={formik.handleChange}
								error={
									formik.touched.name && formik.errors.name
								}
								helperText={
									(formik.touched.name &&
										formik.errors.name) ||
									" "
								}
							/>
						</Stack>
						<Stack>
							<Typography>Descripción</Typography>
							<TextField
								value={formik.values.description}
								id="description"
								onChange={formik.handleChange}
								error={
									formik.touched.description &&
									formik.errors.description
								}
								helperText={
									(formik.touched.description &&
										formik.errors.description) ||
									" "
								}
							/>
						</Stack>
					</Stack>
					<Stack justifyContent="center" direction="row" spacing={4}>
						<Button
							variant="contained"
							onClick={formik.handleSubmit}
						>
							Crear
						</Button>
						<Button variant="outlined" onClick={handleClose}>
							Cancelar
						</Button>
					</Stack>
				</Stack>
			</DialogContent>
		</>
	);
}
