import React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import OutlinedInput from "@mui/material/OutlinedInput";
import { InputLabel } from "@mui/material";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InsidePage from "../../../Templates/InsidePage";

export default function GenerateEvents() {
	const [date, setDate] = React.useState(null);
	const [type, setType] = React.useState("");
	const [currency, setCurrency] = React.useState("");
	const [vat, setVat] = React.useState("");
	const [discount, setDiscount] = React.useState("");

	const handleTypeChange = (event) => {
		setType(event.target.value);
	};

	const handleCurrencyChange = (event) => {
		setCurrency(event.target.value);
	};

	const handleVatChange = (event) => {
		setVat(event.target.value);
	};

	const handleDiscountChange = (event) => {
		setDiscount(event.target.value);
	};

	return (
		<InsidePage title="Generar Eventos">
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					gap: "50px",
					marginBottom: "20px",
				}}
				component={"form"}
			>
				<Grid container spacing={1}>
					<Grid item md={6}>
						<FormControl fullWidth>
							<InputLabel>Folio</InputLabel>
							<OutlinedInput label="Folio" />
						</FormControl>
					</Grid>
					<Grid item md={6}>
						<FormControl fullWidth>
							<InputLabel>Referencia</InputLabel>
							<OutlinedInput label="Referencia" fullWidth />
						</FormControl>
					</Grid>
					<Grid item md={12}>
						<FormControl fullWidth>
							<InputLabel>Nombre del evento</InputLabel>
							<OutlinedInput label="Nombre del evento" fullWidth />
						</FormControl>
					</Grid>
					<Grid item md={12}>
						<FormControl fullWidth>
							<InputLabel>Concepto</InputLabel>
							<OutlinedInput label="Concepto" fullWidth />
						</FormControl>
					</Grid>
					<Grid item md={12}>
						<FormControl fullWidth>
							<InputLabel>Descripcíon</InputLabel>
							<OutlinedInput
								label="Descripcion"
								multiline
								minRows={4}
								fullWidth
							/>
						</FormControl>
					</Grid>
				</Grid>
				<Grid container spacing={1}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<Grid item md={6}>
							<DatePicker
								label="fecha"
								value={date}
								onChange={(newValue) => {
									setDate(newValue);
								}}
								renderInput={(params) => <TextField fullWidth {...params} />}
							/>
						</Grid>
						<Grid item md={6}>
							<FormControl fullWidth>
								<InputLabel>Tipo de ingreso</InputLabel>
								<Select
									label="Tipo de ingreso"
									value={type}
									onChange={handleTypeChange}
								>
									<MenuItem value={10}>Diez</MenuItem>
									<MenuItem value={20}>Veinte</MenuItem>
									<MenuItem value={30}>Treinta</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item md={6}>
							<FormControl fullWidth>
								<InputLabel>Monto</InputLabel>
								<OutlinedInput label="Monto" fullWidth></OutlinedInput>
							</FormControl>
						</Grid>
						<Grid item md={6}>
							<FormControl fullWidth>
								<InputLabel>Moneda</InputLabel>
								<Select
									label="Moneda"
									value={currency}
									onChange={handleCurrencyChange}
								>
									<MenuItem value={10}>Diez</MenuItem>
									<MenuItem value={20}>Veinte</MenuItem>
									<MenuItem value={30}>Treinta</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item md={6}>
							<FormControl fullWidth>
								<InputLabel>Iva</InputLabel>
								<Select label="Iva" value={vat} onChange={handleVatChange}>
									<MenuItem value={10}>Diez</MenuItem>
									<MenuItem value={20}>Veinte</MenuItem>
									<MenuItem value={30}>Treinta</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item md={6}>
							<FormControl fullWidth>
								<InputLabel>Descuento</InputLabel>
								<Select
									label="Descuento"
									value={discount}
									onChange={handleDiscountChange}
								>
									<MenuItem value={10}>Diez</MenuItem>
									<MenuItem value={20}>Veinte</MenuItem>
									<MenuItem value={30}>Treinta</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item md={6}>
							<Button fullWidth variant="contained">
								Generar Evento
							</Button>
						</Grid>
						<Grid item md={6}>
							<Button fullWidth variant="outlined">
								imprimir
							</Button>
						</Grid>
					</LocalizationProvider>
				</Grid>
			</Box>
			<OutlinedInput multiline minRows={8} fullWidth />
		</InsidePage>
	);
}
