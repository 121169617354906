import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { BasicButton } from "../../../Templates/input/BasicButton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { Avatar, IconButton } from "@mui/material";
import CloseIcon from "../../../sources/Icons/CloseIcon.svg";
import { DebtContext } from "../../../Context/DebtContext";
import { UserContext } from "../../../Context/StudentContext";
import { redirect } from "react-router-dom";

export function ConceptsModal() {
	const { DebtData, handleClose, addDebtByUuid } = DebtContext();
	const { UserData } = UserContext();
	return (
		<>
			<Stack
				sx={{ justifyContent: "space-between" }}
				direction="row"
				width={"100%"}
				spacing={8}
			>
				{console.log("DebtData.concept", DebtData.concept)}
				<DialogTitle
					sx={{
						fontSize: "48px",
						fontWeight: "700",
						paddingBottom: 0,
					}}
				>
					{DebtData.concept.payment_concept.name}
				</DialogTitle>
				<IconButton
					sx={{
						alignSelf: "flex-start",
					}}
					disableRipple
					disableFocusRipple
					disableTouchRipple
					onClick={() => handleClose()}
				>
					<Avatar variant="icon15x15" src={CloseIcon} />
				</IconButton>
			</Stack>
			<DialogContent
				sx={{
					display: "grid",
					fontSize: "20px",
					fontWeight: "600",
					paddingTop: 0,
				}}
			>
				{`Disponible del ${new Date(
					DebtData.concept.start_date
				).toLocaleDateString("es-MX", {
					day: "2-digit",
					month: "long",
					year: "numeric",
				})} al ${new Date(
					DebtData.concept.expiry_date
				).toLocaleDateString("es-MX", {
					day: "2-digit",
					month: "long",
					year: "numeric",
				})}`}
				<br />
				<br />
				{/* {dialogContent} */}

				{DebtData.concept.payment_concept.description}
				<Box sx={{ alignSelf: "flex-end" }}>
					Inversión:&nbsp;
					{DebtData.concept.payment_concept.amount.toLocaleString(
						"es-MX",
						{
							style: "currency",
							currency: "MXN",
						}
					)}
					&nbsp;MXN
				</Box>
			</DialogContent>
			<DialogActions
				sx={{
					width: "75%",
					margin: "20px auto",
				}}
			>
				<BasicButton
					text="Obtener adeudo"
					variant="contained"
					onClick={() => {
						addDebtByUuid(UserData.uuid);
						redirect("/PagosPendientes");
					}}
				/>
				<BasicButton
					text="cancelar"
					variant="outlined"
					onClick={handleClose}
				/>
			</DialogActions>
		</>
	);
}
