import React from "react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";

import MoneyIcon from "../../../sources/Icons/MoneyIcon.svg";
import EditPencilIcon from "../../../sources/Icons/EditPencilIcon.svg";
import { DebtContext } from "../../../Context/DebtContext";

export default function AdminConceptsCard({ concept, deleteMode }) {
	const { AddStaged, RemoveStaged, staged } = DebtContext();
	const navigate = useNavigate();

	const isChecked =
		staged.find((s) => {
			s.uuid === concept.uuid;
		}) || false;

	const [checked, setChecked] = React.useState(isChecked);

	function handleChange() {
		if (checked) {
			setChecked(false);
			RemoveStaged(concept);
		} else {
			setChecked(true);
			AddStaged(concept);
		}
	}

	return (
		<Card
			sx={{
				display: "flex",
				flexDirection: "row",
				borderRadius: "15px",
				background: "#76AB00",
				color: "white",
				height: "180px",
				position: "relative",
			}}
			elevation={0}
		>
			{deleteMode && (
				<Checkbox
					color="secondary"
					sx={{ position: "absolute", right: 0 }}
					checked={checked}
				/>
			)}
			<CardActionArea
				onClick={() =>
					deleteMode
						? handleChange()
						: navigate("/admin/conceptos/editar", { state: concept })
				}
			>
				<CardContent
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 4,
						height: "calc(100% - 32px)",
						justifyContent: "space-between",
					}}
				>
					<Typography
						sx={{ textTransform: "capitalize" }}
						variant="titleContent"
					>
						{concept.name}
					</Typography>
					<Stack direction="row" sx={{ alignItems: "center" }}>
						<Stack width="100%" spacing={1}>
							<Stack
								direction="row"
								sx={{ alignItems: "center" }}
								spacing={2}
							>
								<Stack direction="row" spacing={1}>
									<Chip
										label="Concepto"
										sx={{
											backgroundColor: "#99D01F",
											color: "white",
											borderRadius: "4px",
											":hover": {
												cursor: "pointer",
											},
										}}
									/>
									<IconButton
										sx={{
											backgroundColor: "#99D01F",
											color: "white",
											borderRadius: "4px",
											width: "32px",
											":hover": {
												backgroundColor: "#99D01F",
											},
										}}
										disableRipple
										disableFocusRipple
										disableTouchRipple
										// disabled
									>
										<Avatar
											variant="icon15x15"
											src={EditPencilIcon}
										/>
									</IconButton>
								</Stack>
							</Stack>
						</Stack>
						<Avatar src={MoneyIcon} variant="icon50x50" />
					</Stack>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}
