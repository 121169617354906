import { Link } from "react-router-dom";
import { Button, Stack, Typography } from "@mui/material";

export default function BalancingStart() {
	return (
		<Stack minWidth={"60%"} maxWidth={"80%"} spacing={7}>
			<Stack spacing={1}>
				<Typography>Conciliar por archivo:</Typography>
				<Stack sx={{ width: "100%" }} direction="row" spacing={1}>
					<Button
						component={Link}
						to="/admin/Conciliacion/exp"
						variant="contained"
						sx={{
							width: "100%",
							maxWidth: "250px",
						}}
					>
						conciliar exp
					</Button>
					<Button
						component={Link}
						to="/admin/Conciliacion/excel"
						variant="contained"
						sx={{
							width: "100%",
							maxWidth: "250px",
						}}
					>
						conciliar excel
					</Button>
				</Stack>
			</Stack>
			<Stack spacing={1}>
				<Typography>Reportes:</Typography>
				<Button
					variant="contained"
					target={"_blank"}
					href={`${process.env.REACT_APP_API_URL}/api/v1/files/uncompleted_payments/`}
					sx={{
						maxWidth: "508px",
					}}
				>
					pagos por aprobar en sia
				</Button>
			</Stack>
		</Stack>
	);
}
