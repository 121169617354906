import faeoB from "../../sources/faeoBlanco.png";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
export default function Def() {
	const theme = useTheme();
	return (
		<Box
			sx={{
				placeSelf: "center",
				display: "flex",
				flexDirection: "row",
				[theme.breakpoints.down("md")]: {
					display: "none",
				},
			}}
		>
			<Box sx={{ display: "flex", alignItems: "center", width: "360px" }}>
				<Avatar sx={{ width: "100px", height: "100px" }} src={faeoB} />
				<Typography
					variant="h5"
					flexWrap={"wrap"}
					color={"white"}
					align={"center"}
					sx={{ paddingX: 1 }}
				>
					Facultad de enfermeria y obstetricia
				</Typography>
			</Box>
		</Box>
	);
}
