import React from "react";

import { Link } from "react-router-dom";

import {
	Avatar,
	Button,
	Grid,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { DebtContext } from "../../../Context/DebtContext";
import AddCard from "../../../Templates/cardAdd";
import InsidePage from "../../../Templates/InsidePage";
import AdminConceptGroupCard from "./Card";
import deleteIcon from "../../../sources/Icons/deleteIcon.svg";
export default function AdminConceptsGroupComponent() {
	const {
		getPaymentConcepts,
		DebtData,
		staged,
		RestartStaged,
		DeleteConcepts,
	} = DebtContext();

	const [deleteMode, setDeleteMode] = React.useState(false);

	function BottomBarDefault() {
		return (
			<>
				<Tooltip title="Borrar conceptos" arrow>
					<IconButton
						onClick={() => setDeleteMode(true)}
						variant="noPadding"
					>
						<Avatar variant="icon30x30" src={deleteIcon} />
					</IconButton>
				</Tooltip>
				<Button
					sx={{ minWidth: "250px", maxHeight: "30px" }}
					variant="contained"
					LinkComponent={Link}
					to="/admin/GruposConceptos"
				>
					Grupos de conceptos
				</Button>
			</>
		);
	}

	function BottomBarDelete() {
		return (
			<Stack
				direction="row"
				sx={{ width: "100%", justifyContent: "space-between" }}
			>
				<Stack
					spacing={1}
					direction="row"
					sx={{
						alignItems: "center",
					}}
				>
					<Tooltip title="Borrar conceptos" arrow>
						<IconButton
							onClick={() => setDeleteMode(true)}
							variant="noPadding"
						>
							<Avatar variant="icon30x30" src={deleteIcon} />
						</IconButton>
					</Tooltip>
					<Typography>Selecciona los conceptos a eliminar</Typography>
					<Typography fontWeight="bold">
						{staged.length}&nbsp;seleccionado(s)
					</Typography>
				</Stack>
				<Stack spacing={1} direction="row">
					<Button
						sx={{ minWidth: "250px", maxHeight: "30px" }}
						variant="contained"
						onClick={() => {
							DeleteConcepts();
							setDeleteMode(false);
							RestartStaged();
						}}
					>
						Eliminar seleccionados
					</Button>
					<Button
						sx={{ minWidth: "250px", maxHeight: "30px" }}
						variant="outlined"
						onClick={() => {
							RestartStaged();
							setDeleteMode(false);
						}}
					>
						Cancelar
					</Button>
				</Stack>
			</Stack>
		);
	}

	React.useEffect(() => {
		getPaymentConcepts();
		console.log("admin DebtData", DebtData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<InsidePage title="Conceptos" visibility="hidden">
			<Grid container spacing={2}>
				{DebtData.allConcepts?.map((concept) => (
					<Grid key={concept.uuid} item xs={4}>
						<AdminConceptGroupCard
							deleteMode={deleteMode}
							concept={concept}
						/>
					</Grid>
				))}
				<Grid item xs={4}>
					<AddCard to="/admin/conceptos/crear" />
				</Grid>
			</Grid>
			<Stack spacing={1} direction="row" variant="BottomSelector">
				{deleteMode ? <BottomBarDelete /> : <BottomBarDefault />}
			</Stack>
		</InsidePage>
	);
}
